<template>
    <div class="content">
    <div class="row">
      <div class="col-2">
          <el-dropdown style="width:100%" @command="handleCommand">
            <el-button type="primary" size="mini" style="width:100%">
              Acciones<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="descarga" v-if="permisos.down">Descargar Filtro</el-dropdown-item>
              <el-dropdown-item command="cargar" v-if="permisos.view">Cargar Registros</el-dropdown-item>
              <el-dropdown-item command="cambiar" v-if="permisos.edit">Cambios Masivos</el-dropdown-item>
              <el-dropdown-item command="eliminar" v-if="permisos.del">Eliminar Registros</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        Años:
          <el-select v-model="anio" placeholder="AÑOS" size="mini" >
            <el-option
              v-for="item in anios"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
        Meses:
          <el-select v-model="mes" placeholder="MESES" size="mini">
            <el-option
              v-for="item in meses"
              :key="item.i"
              :label="item.d"
              :value="item.d">
            </el-option>
          </el-select>
        Regiones:
          <el-select v-model="region" placeholder="REGIONES" size="mini" @change="getGerencias(0)">
            <el-option
              v-for="item in regiones"
              :key="item.i"
              :label="item.d"
              :value="item.d">
            </el-option>
          </el-select>
        <span v-if="gerencias.length != 0">Gerencias:</span>
          <el-select v-if="gerencias.length != 0" v-model="gerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(0)">
            <el-option
              v-for="item in gerencias"
              :key="item.i"
              :label="item.d"
              :value="item.d">
            </el-option>
          </el-select>
          <span v-if="cedis.length != 0">Cedis:</span>
          <el-select v-if="cedis.length != 0" v-model="cedi" placeholder="CEDIS" size="mini" >
            <el-option
              v-for="item in cedis"
              :key="item.i"
              :label="item.d"
              :value="item.d">
            </el-option>
          </el-select>
          
          <el-button size="mini" type="primary" class="mt-3" style="width:100%"
          @click="filtrar"
          >
            Filtrar
          </el-button>

        </div>

      <div class="col-10">
          <v-data-table
            dense
            :headers="headers"
            :items="desserts"
            item-key="valCar_id"
            class="elevation-1"
            :item-class="rowClass"
            :options="pagination"
            hide-default-footer
          >
          </v-data-table>
          <div
            slot="footer"
            class="col-12 my-2 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Registros <b>{{ start + 1 }}</b> A <b>{{ start + itemsT }}</b> de <b>{{ total }}</b>
              </p>
            </div>
            <b-pagination
              v-model="pagination.currentPage"
              :per-page="pagination.limit"
              :total-rows="total"
              size="sm"
              @change="newPagina"
              ></b-pagination>
          </div>

            <el-dialog
              title="Eliminar Registros"
              :visible.sync="dgEliminarReg"
              width="30%">
              <p>Ingrese los Id a Eliminar</p>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="Ids separados por coma (,)"
                v-model="idsEliminar">
              </el-input>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dgEliminarReg = false">Cancelar</el-button>
                <el-button type="primary" @click="eliminarRegistros">Eliminar</el-button>
              </span>
            </el-dialog>

            <el-dialog
              title="Cambios Masivos"
              :visible.sync="dgCambio"
              width="30%">
              <el-upload
                class="upload-demo mb-3"
                :auto-upload="false"
                :on-change="masivoArchivo"
                >
                <el-button size="small" type="primary">Clic para seleccionar archivo</el-button>
                <!--<div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>-->
              </el-upload>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dgCambio = false">Cancelar</el-button>
                <el-button type="primary" @click="cambiosMasivos">Enviar</el-button>
              </span>
            </el-dialog>
          </div>
          <!---->
           <modal
              :show.sync="mdSubir" 
              headerClasses="justify-content-center"
            >
              <h4 slot="header" class="title title-up">Cargar Registros </h4>
              <!--action="#" method="#" @submit.prevent>-->
                <form class="form-horizontal" ref="formSubir">
                  <div class="row">
                    <label class="col-md-3 col-form-label">Archivo</label>
                    <div class="col-md-9">
                      <el-upload
                        class="upload-demo"
                        :auto-upload="false"
                        :on-change="subirArchivo">
                        <el-button size="small" type="primary">Clic para abrir archivo</el-button>
                        <!--<div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>-->
                      </el-upload>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 offset-4 mt-3">
                      <el-button type="info" size="mini" @click="subirCartera">Enviar Información</el-button>
                    </div>
                  </div>
                </form>
            </modal>

     <el-dialog
       :title="titleVal"
       :visible.sync="dgValidate"
       width="30%">
       <p>Contraseña</p>
       <el-input v-model="passValidate" placeholder="Contraseña"></el-input>
       <span slot="footer" class="dialog-footer">
         <el-button @click="dgValidate = false">Cancelar</el-button>
         <el-button type="primary" @click="validateAction">Confirmar</el-button>
       </span>
     </el-dialog>
    </div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    </div>
</template>

<script>
import { TimeSelect,
          DatePicker,
          Select,
          Option,
          Button,
          Dropdown, DropdownMenu, DropdownItem, Switch, Upload, Dialog } from 'element-ui';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import axios from 'axios';
import { Modal } from 'src/components';
// import { API_KEY } from "./API_KEY";
import { Loader, LoaderOptions } from "google-maps";

const loader = new Loader("AIzaSyDnsLdCKdxoFbJMxVfZHMDnr_SB-L-T7nA");

export default {
  components: {
      Modal,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog
  },
  data() {
    return {
      overlay: false,
      headers: [
          { text: 'Año', value: 'cierre_anio', width: 100, sortable: false },
          { text: 'Mes', value: 'cierre_mes', width: 100, sortable: false },
          { text: 'Modelo', value: 'cierre_modelo', width: 100, sortable: false },
          { text: 'CAR ID', value: 'car_id', width: 100, sortable: false },
          { text: 'VAL ID', value: 'valCar_id', width: 100, sortable: false },
          { text: 'CallCenter', value: 'Call_Center_Asignado', width: 150, sortable: false },
          { text: 'Region', value: 'valCar_reg', width: 150, sortable: false },
          { text: 'Gerencia', value: 'valCar_gerencia', width: 150, sortable: false },
          { text: 'Cedis', value: 'valCar_cds', width: 150, sortable: false },
          { text: 'Estatus Visita', value: 'valCar_status_abrio', width: 150, sortable: false },
          { text: 'Estatus', value: 'valCar_val_status', width: 150, sortable: false },
          { text: 'Visitar', value: 'car_visitar', width: 150, sortable: false },
          { text: 'Pendientes', value: 'valCar_contador_pendientes', width: 150, sortable: false },
          { text: 'Nuevo', value: 'car_nuevo', width: 150, sortable: false },
          { text: 'Razon Baja', value: 'valCar_razon_baja', width: 150, sortable: false },
          { text: 'Ruta', value: 'valCar_rt_prev', width: 150, sortable: false },
          { text: 'NUD', value: 'valCar_NUD', width: 150, sortable: false },
          { text: 'Nombre', value: 'valCar_nombre', width: 250, sortable: false },
          { text: 'Direccion', value: 'valCar_dir', width: 250, sortable: false },
          { text: 'Num. Ext.', value: 'valCar_num_ext', width: 100, sortable: false },
          { text: 'Num. Int.', value: 'valCar_num_int', width: 100, sortable: false },
          { text: 'Colonia', value: 'valCar_col', width: 250, sortable: false },
          { text: 'Calle 1', value: 'valCar_cll_1', width: 150, sortable: false },
          { text: 'Calle 2', value: 'valCar_cll_2', width: 150, sortable: false },
          { text: 'C.P.', value: 'valCar_cp', width: 100, sortable: false },
          { text: 'Frec. Visita', value: 'valCar_frec_vis', width: 150, sortable: false },
          { text: 'Suscripción', value: 'valCar_suscripcion', width: 150, sortable: false },
          { text: 'Lada 1', value: 'valCar_lada_1', width: 150, sortable: false },
          { text: 'Telefono1', value: 'telefono1', width: 150, sortable: false },
          { text: 'Telefono2', value: 'telefono2', width: 150, sortable: false },
          { text: 'Telefono3', value: 'telefono3', width: 150, sortable: false },
          { text: 'Telefono4', value: 'telefono4', width: 150, sortable: false },
          { text: 'Contacto', value: 'valCar_contacto_adicional', width: 150, sortable: false },
          { text: 'Telefono Ad', value: 'valCar_teladi', width: 150, sortable: false },
          { text: 'Comentario', value: 'valCar_comments', width: 150, sortable: false },
          { text: 'Giro', value: 'valCar_giro', width: 150, sortable: false },
          { text: 'Email', value: 'valCar_email', width: 150, sortable: false },
          { text: 'Factura', value: 'valCar_factura', width: 150, sortable: false },
          { text: 'Foto', value: 'valCar_f1', width: 150, sortable: false },
          { text: 'Fecha Llamada', value: 'Fecha_Call', width: 150, sortable: false },
          { text: 'Hora Llamada', value: 'Hora_Call', width: 150, sortable: false },
          { text: 'UID', value: 'uid', width: 100, sortable: false },
          { text: 'Fecha Promotor', value: 'Fecha_Promotor', width: 150, sortable: false },
          { text: 'Hora Promotor', value: 'Hora_Promotor', width: 150, sortable: false },
          { text: 'Fecha Val', value: 'Fecha_validacion', width: 150, sortable: false },
          { text: 'Hora Val', value: 'Hora_validacion', width: 150, sortable: false },
          { text: 'Almacenamiento', value: 'valCar_s3_bucket', width: 150, sortable: false },
          { text: 'S3', value: 'valCar_bk', width: 150, sortable: false },
          { text: 'Update Masivo', value: 'valCar_ms_updt', width: 150, sortable: false },
          { text: 'Fecha Val', value: 'valCar_val_date', width: 150, sortable: false },
          { text: 'Hora Val', value: 'valCar_val_time', width: 150, sortable: false },
          { text: 'Gepp Rev', value: 'valCar_gepp_rev', width: 150, sortable: false },
          { text: 'Nota', value: 'valCar_notas', width: 150, sortable: false },
          { text: 'NUD Duplicado', value: 'valCar_nud_duplicado', width: 150, sortable: false },
          { text: 'Pendiente', value: 'valCar_motivo_pendiente', width: 150, sortable: false },
          { text: 'Programado', value: 'valCar_motivo_programado', width: 150, sortable: false },
          { text: 'Frecuencia', value: 'valCar_frecuencia_1', width: 150, sortable: false },
          { text: 'Num. Garrafones', value: 'valCar_cantidad_garrafones_1', width: 150, sortable: false },
          { text: 'Periodo', value: 'valCar_sem_quin_men', width: 150, sortable: false },
          { text: 'Num. Garrafones', value: 'valCar_cantidad_garrafones_2', width: 150, sortable: false },
          { text: 'Nombre Responsable', value: 'valCar_nombre_responsable', width: 150, sortable: false },
          { text: 'Ext. Responsable', value: 'valCar_extencion_responsable', width: 150, sortable: false },
          { text: 'Hora Llamada', value: 'valCar_hora_preferente_llamada', width: 150, sortable: false },
          { text: 'Razon', value: 'razon_no_acepta_televenta', width: 150, sortable: false },
          { text: 'Fac. Anverso', value: 'valCar_photo_factura_anverso', width: 150, sortable: false },
          { text: 'Fac. Reverso', value: 'valCar_photo_factura_reverso', width: 150, sortable: false },
          { text: 'Bandera GPS', value: 'valCar_bandera_gps', width: 150, sortable: false },
          { text: 'Num. Empleado', value: 'valCar_numero_empleado', width: 150, sortable: false },
          { text: 'Nombre Empleado', value: 'nombre_empleado', width: 150, sortable: false }
        ],
      desserts: [],
      pageCount: 0,
      start: 0,
      total: 0,
      itemsT: 0,
      pagination: {
        page: 1,
        limit: 13,
        itemsPerPage: 13,
        currentPage: 1
      },
      isBusy: false,
      region: null,
      regiones: [],
      gerencia: null,
      gerencias: [
      ],
      cedi: null,
      cedis: [
      ],
      pre: null,
      preventas: [
        ],
      nuevo: null,
      nuevos: [
      { i: -1, d: 'Todos' },
      { i:  0, d: 'Cartera' },
      { i:  1, d: 'Nuevos' }
        ],
      estatus: null,
      itmEstatus: [
      { i: -1, d: 'Todos' },
      { i:  0, d: 'Por Revisar' },
      { i:  3, d: 'Transformado' },
      { i:  2, d: 'Baja' }
    ],
    nudFind: [],
    hash: '',
    uid: '',
    anios: [],
    anio: '',
    meses: [],
    mes: '',
    titleVal: '',
    currentVal: '',

    mdSubir: false,
    mdFileXls: null,
    mdBuscar: '',
    mdRegion: null,
    mdRegiones: [],
    mdGerencia: null,
    mdGerencias: [],
    mdCedi: null,
    mdCedis: [],

    dgSubir: false,
    passSubir: null,
    dgValidate: false,
    dgEliminarReg: false,
    passValidate: '',
    idsEliminar: '',
    dgMasivo: false,
    passMasivos: '',
    dgCambio: false,
    resultadoMasivo: '',
    masvisoFileXls: null,
    dgDetalle: false,
    currentClient: '',
    currentImagen: '',
    permisos: {}
    };
  },
  methods: {
    rowClass(item) {
      //if (!item) return
      if (item.car_val_status == '3') return 'table-primary'
      if (item.car_val_status == '2') return 'table-danger'
    },
    paginate(p) {
      console.log(p);
      this.pagination.page = p

      this.pagination.start = this.pagination.limit * (p - 1)
      this.pagination.currentPage = p

      this.getData()
    },
    getData() {
      //console.log(this.pre.join(','))
      //this.isBusy = true
      this.overlay = true
      let json ={
        c: "CierreFactura",
        cmd: "getDataFactura",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        //ip: "189.167.28.167",
        opr_id: 1,
        u_reg_id: this.region && this.region != 'TODAS' ? this.region : -1,
        ger_id: this.gerencia && this.gerencia != 'TODAS' ? this.gerencia.replace('Ñ', 'N') : -1,
        cds_id: this.cedi && this.cedi != 'TODOS' ? this.cedi.replace('Ñ', 'N') : -1,
        anio: this.anio,
        mes: this.mes && this.mes != 'TODOS' ? this.mes : -1,
        page: this.pagination.page,
        start: this.start,
        limit: this.pagination.limit
      };

      if(this.nudFind != ''){
        json.textN = this.nudFind
      }

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data);
        this.overlay = false
        var resp = data.data.rows
        //this.items = resp;
        //this.pagination.rowsTotal = data.data.results

        this.desserts = resp;
        this.pageCount = Math.ceil(data.data.results / this.pagination.limit)
        this.total = data.data.results
        this.itemsT = resp.length
        //this.overlay = false
        //this.isBusy = false
      })
    },
    getRegiones(sc){

      let json ={
        c: "geppValida",
        cmd: "getRegionesJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        if(sc == 0){
          this.regiones = data.data
          //
          this.regiones.unshift({ i: -1, d: 'TODAS' })
          //this.mdRegiones.splice(0,1)
        }else if(sc == 1){
          this.mdRegiones = data.data
        }
          
        
      })
    },
    getGerencias(sc){
        this.gerencia = null
        this.cedi = null
        this.cedis = []

        var region = this.regiones.find( r => r.d === this.region ).i
      if(region == -1){
        this.gerencias = []
        return false
      }
      
      let json ={
        c: "geppValida",
        cmd: "getGerenciasJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        reg: region
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.gerencias = data.data
          this.gerencias.unshift({ i: -1, d: 'TODAS' })
        }else{
          this.mdGerencias = data.data
        }
      })
    },
    getCedis(sc){
      
      this.cedi = null
      var gerencia = this.gerencias.find( g => g.d == this.gerencia).i
      if(gerencia == -1){
        
        this.cedis = []

        return false
      }

      let json ={
        c: "geppValida",
        cmd: "getCedisJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        ger: gerencia
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.cedis = data.data
          this.cedis.unshift({ i: -1, d: 'TODOS' })
        }else{
          this.mdCedis = data.data
        }
      })
    },
    getPreventa(){
      let json ={
        c: "geppValida",
        cmd: "getRtPrevJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        cds: this.cedi
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data.data);
        this.preventas = data.data
        this.preventas.unshift({ i: -1, d: 'TODAS' })
      })
    },
    getAnios(){
      let json ={
        c: "cierreFactura",
        cmd: "getAniosFactura",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data.data);
        this.anios = data.data
        this.anios.unshift({ i: 0, d: 'TODOS' })
      })
    },
    getMeses(){
      let json ={
        c: "cierreFactura",
        cmd: "getMesesFactura",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data.data);
        this.meses = data.data
        this.meses.unshift({ i: 0, d: 'TODOS' })
      })
    },
    filtrar(){
      this.pagination.page = 1
      this.start = 0
      this.getData()
    },
    descargaFiltro(){
      var str = ''
      var rt = this.region == 'TODAS' ? -1 : this.region
      var gt = this.gerencia == 'TODAS' ? -1 : this.gerencia ? this.gerencia.replace('Ñ', 'N') : -1
      var ct = this.cedi == 'TODOS' ? -1 : this.cedi ? this.cedi.replace('Ñ', 'N') : -1
      var at = this.anio == 'TODOS' ? -1 : this.anio
      var mt = this.mes == 'TODOS' ? -1 : this.mes

      str += rt ? "&u_reg="+rt : ''
      str += gt ? "&ger="+gt : ''
      str += ct ? "&cds="+ct : ''
      str += at && at != '' ? "&anio="+at : ''
      str += mt ? "&mes="+this.mes : ''

      window.location = this.$api_url+"?c=CierreFactura&cmd=dowDataFactura&exec=self&uid="+this.uid+"&hash="+this.hash+str;
    },
    handleCommand(cm){
      switch (cm){
        case 'descarga':
          this.descargaFiltro()
        break
        case 'eliminar':
          this.idsEliminar = null
          this.dgValidate = true
          this.titleVal = "Eliminar Registros"
          this.currentVal = "EliminarRegistros"
        break
        case 'cambiar':
          this.masvisoFileXls = null
          this.dgValidate = true
          this.titleVal = "Cambios Masivos"
          this.currentVal = 'CambiosMasivos'
        break
        case 'cargar':
          this.mdFileXls = null
          this.dgValidate = true
          this.titleVal = "Subir Registros"
          this.currentVal = 'CargarRegistros'
        break
      }
    },
    validateSubir(){
      if(this.passSubir == 'admin#'){
        this.dgSubir = false
        this.passSubir = ''
        this.mdSubir = true
      }else{
        //
        this.$notify({
            title: 'Error',
            message: 'Contraseña incorrecta, verifica e intenta de nuevo.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      }
    },
    validateAction(){
      if(this.passValidate == 'admin#'){
        this.dgValidate = false
        this.passValidate = ''
        if(this.currentVal == 'CargarRegistros')  this.mdSubir = true
        if(this.currentVal == 'CambiosMasivos')  this.dgCambio = true
        if(this.currentVal == 'EliminarRegistros')  this.dgEliminarReg = true
      }else{
        //
        this.$notify({
            title: 'Error',
            message: 'Contraseña incorrecta, verifica e intenta de nuevo.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      }
    },
    validateMasivo(){
      if(this.passMasivos == 'admin#'){
        this.dgMasivo = false
        this.passMasivos = ''
        this.dgCambio = true
      }else{
        this.$notify({
            title: 'Error',
            message: 'Contraseña incorrecta, verifica e intenta de nuevo.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      }
    },
    subirArchivo(file){
      //console.log(file)
      this.mdFileXls = file.raw
    },
    masivoArchivo(){
      this.masvisoFileXls = file.raw
    },
    buscarNud(){
      this.pagination.page = 1
      this.start = 0
      this.getData()
    },
    eliminarRegistros(){
      let json ={
        c: "CierreFactura",
        cmd: "getUpEliminarFactura",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        id: this.idsEliminar
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
          this.$notify({
            title: '',
            message: 'Clientes Eliminados con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    cambiosMasivos(){
      this.overlay = true
      this.pagination.page = 1
      this.start = 0
      const formData = new FormData();
      formData.append('wnd_upld_xls', this.masvisoFileXls);
      formData.append('wnd_upld_act', 2);
      formData.append('wnd_upld_uid', this.uid);

      var strl = '?'
      //let json ={
        strl += "c=CierreFactura"
        strl += "&cmd=processFormFactura"
        strl += "&exec=self"
        strl += "&uid="+this.uid
        strl += "&hash="+this.hash
      //};
      
      axios.post(this.$api_url+strl, formData)
      .then(data=>{
        this.overlay = false
        this.getData()
        if(data.data.success){
          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
        }else{
	        this.$notify({
              title: '',
              message: 'Error al cargar el archivo.',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'warning',
              timeout: 4000,
              icon: 'tim-icons icon-bell-55'
            });
        }
      })
    },
    subirCartera(){

      this.overlay = true
      this.pagination.page = 1
      this.start = 0
      const formData = new FormData();
      formData.append('wnd_upld_xls', this.mdFileXls);
      formData.append('wnd_upld_act', 1);
      formData.append('wnd_upld_uid', this.uid);

      var strl = '?'
      //let json ={
        strl += "c=CierreFactura"
        strl += "&cmd=processFormFactura"
        strl += "&exec=self"
        strl += "&uid="+this.uid
        strl += "&hash="+this.hash
      //};
      
      axios.post(this.$api_url+strl, formData)
      .then(data=>{
        this.overlay = false
        this.getData()
        if(data.data.success){
        this.$notify({
            title: '',
            message: 'Cartera cargada con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
        }else{
	        this.$notify({
              title: '',
              message: 'Error al cargar el archivo.',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'warning',
              timeout: 4000,
              icon: 'tim-icons icon-bell-55'
            });
        }
      })
    },
    newPagina(p){
      // console.log(p);
      this.pagination.page = p
      this.start = this.pagination.limit * (p - 1)
      this.pagination.currentPage = p

      this.getData()
    },
  },
  mounted() {

    if(localStorage.getItem('key')) {
      this.hash = localStorage.getItem('key')
      this.uid = localStorage.getItem('uid')
      // console.log( JSON.parse(localStorage.getItem('permission')));
      this.permisos = JSON.parse(localStorage.getItem('permission')).cartera_ini.actions

        //console.log(this.permisos);
      this.getRegiones(0)
      this.getAnios()
      this.getMeses()
      this.getData()
    } else {
       this.$router.push('login');
    }

  }
}
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content{
  font-size: inherit !important;
}

.xs{
  min-width: 100px;
  text-justify: auto;
}

.sm{
  min-width: 150px;
  text-justify: auto;
}

.mb{
  min-width: 200px;
  text-justify: auto;
}

.lm{
  min-width: 250px;
  text-justify: auto;
}

.xl{
  min-width: 300px;
  text-justify: auto;
}

.table th {
  text-align: left;
}

.success {
  color: blue;
}

.select_0 {
  padding-right: 5px;
}

.select {
  padding-left: 0px;
  padding-right: 5px;
}

.el-input__icon{
  width: 0px;
}

span.el-range-separator{
  width: 15px;
}

.el-range-input{
  width: 50%;
}

.card-map {
  min-height: 150px;
  .map {
    height: 150px;
    width: 100%;
  }
}
</style>
