<template>
  <div class="content">
    <div class="row">
      <div class="col-2">
          <el-dropdown style="width:100%" @command="handleCommand">
            <el-button type="primary" size="mini" style="width:100%">
              Acciones<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="activa" v-if="permisos.edit">Activar Todas las Frecuencias</el-dropdown-item>
              <el-dropdown-item command="descarga" v-if="permisos.down">Descargar Selección</el-dropdown-item>
              <el-dropdown-item command="descargaFotos" v-if="permisos.down">Descargar Fotos</el-dropdown-item>
              <el-dropdown-item command="filtrar">Filtrar por IDs</el-dropdown-item>
              <el-dropdown-item command="filtrarNuds">Filtrar por NUDS</el-dropdown-item>
              <el-dropdown-item command="cambiar" v-if="permisos.mass">Cambios Masivos</el-dropdown-item>
              <el-dropdown-item command="asignar">Re Asignar</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        Regiones:
          <el-select v-model="region" placeholder="REGIONES" size="mini" @change="getGerencias(0)">
            <el-option
              v-for="item in regiones"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
        <span v-if="gerencias.length != 0">Gerencias:</span>
          <el-select v-if="gerencias.length != 0" v-model="gerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(0)">
            <el-option
              v-for="item in gerencias"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="cedis.length != 0">Cedis:</span>
          <el-select v-if="cedis.length != 0" v-model="cedi" placeholder="CEDIS" size="mini" @change="getPreventa(0)">
            <el-option
              v-for="item in cedis"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="usuarios.length != 0">Usuarios:</span>
          <el-select v-if="usuarios.length != 0" v-model="usuario" placeholder="Usuarios" size="mini" multiple collapse-tags>
            <el-option
              v-for="item in usuarios"
              :key="item.i+'us'"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="preventas.length != 0">Preventa:</span>
          <el-select v-if="preventas.length != 0" v-model="pre" placeholder="PREVENTA" size="mini" multiple collapse-tags>
            <el-option
              v-for="item in preventas"
              :key="item.i+'pr'"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Estatus:
          <el-select v-model="estatus" placeholder="ESTATUS" size="mini">
            <el-option
              v-for="item in status"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Validado:
          <el-select v-model="validado" placeholder="VALIDADO" size="mini">
            <el-option
              v-for="item in validados"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Frecuencia:
          <el-select v-model="frecuencia" placeholder="FRECUENCIA" size="mini" >
            <el-option
              v-for="item in frecuencias"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Fechas:
            <el-date-picker
              type="daterange"
              placeholder="Date Time Picker"
              v-model="dateTimePicker"
               size="mini"
               format="dd-MM-yyyy"
               value-format="yyyy-MM-dd"
               prefix-icon="false"
               class="el-input__icon"
               range-separator="A"
               style="font-size:8px; padding:3px 0px 3px 6px;"
               
            >
            </el-date-picker>
          <el-button size="mini" type="primary" class="mt-3" style="width:100%"
          @click="filtrar"
          >
            Filtrar
          </el-button>
        </div>
      <div class="col-10">
          <v-data-table
            dense
            :headers="headers"
            :items="desserts"
            item-key="valCar_id"
            :item-class="rowClass"
            :options="pagination"
            hide-default-footer
            @dblclick:row="eventRow"
          >
            <template v-slot:item.acciones="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="eventDblRow(item)"
                >
                  mdi-pencil
                </v-icon>
          </template>
          </v-data-table>
          <div
            slot="footer"
            class="col-12 my-2 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Registros <b>{{ start + 1 }}</b> A <b>{{ start + itemsT }}</b> de <b>{{ total }}</b>
              </p>
            </div>
            <b-pagination
              v-model="pagination.currentPage"
              :per-page="pagination.limit"
              :total-rows="total"
              size="sm"
              @change="newPagina"
              ></b-pagination>
          </div>
          <!--<div class="row pt-2 justify-content-between">
            <div class="col-4">
              Registros de {{ start + 1 }} A {{ start + itemsT }} de {{ total }}
            </div>
            <div class="col-6">
              <v-pagination
                v-model="pagination.page"
                :length="pageCount"
                :total-visible="7"
                @input="newPagina($event)"
              ></v-pagination>
            </div>
          </div>-->
      </div>
    </div>

    <!-- modals-->
    <el-dialog
      title="Filtrar Registros"
      :visible.sync="dgFiltrar"
      width="30%">
      <p>Ingrese los Ids</p>
      <el-input
        type="textarea"
        :rows="2"
        placeholder="Ids separados por coma (,)"
        v-model="idsFiltrar">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dgFiltrar = false">Cancelar</el-button>
        <el-button type="primary" @click="filtrarRegistros">Filtrar</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="Filtrar Registros NUDs"
      :visible.sync="dgFiltrarNuds"
      width="30%">
      <p>Ingrese los NUDs</p>
      <el-input
        type="textarea"
        :rows="2"
        placeholder="NUDs separados por coma (,)"
        v-model="nudsFiltrar">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dgFiltrarNuds = false">Cancelar</el-button>
        <el-button type="primary" @click="filtrarRegistros">Filtrar</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="Activar/Desactivar Frecuencias"
      :visible.sync="dgActivar"
      width="35%">
      <div data-app>
                <form class="form-horizontal" ref="frActiva">
                  <div class="row">
                    <label class="col-md-3 col-form-label">Región</label>
                    <div class="col-md-9">
                    <el-select v-model="itmRegion" placeholder="REGIONES" size="mini" @change="getGerencias(1)">
                        <el-option
                          v-for="item in itemsRegiones"
                          :key="item.i"
                          :label="item.d"
                          :value="item.i">
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-md-3 col-form-label">Gerencia</label>
                    <div class="col-md-9">
                      <el-select v-model="itmGerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(1)">
                        <el-option
                          v-for="item in itemsGerencias"
                          :key="item.i"
                          :label="item.d"
                          :value="item.i">
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-md-3 col-form-label">Cedis</label>
                    <div class="col-md-9">
                    <el-select v-model="itmCedis" placeholder="CEDIS" size="mini" @change="getPreventa(1)">
                      <el-option
                        v-for="item in itemsCedis"
                        :key="item.i"
                        :label="item.d"
                        :value="item.i">
                      </el-option>
                    </el-select>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-md-3 col-form-label">Ruta</label>
                    <div class="col-md-9">
                    <el-select v-model="itmRuta" placeholder="RUTA" size="mini" multiple>
                      <el-option
                        v-for="item in itemsRutas"
                        :key="item.i"
                        :label="item.d"
                        :value="item.i">
                      </el-option>
                    </el-select>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 offset-4 mt-3">
                      <el-button type="primary" size="mini" @click="activaFrecuencias">Activar/Desactivar</el-button>
                    </div>
                  </div>
                </form>
      </div>
    </el-dialog>
    <el-dialog
       title="Cambios Masivos"
       :visible.sync="dgCambios"
       width="30%">
       <el-upload
         class="upload-demo mb-3"
         :auto-upload="false"
         :on-change="masivoArchivo"
         >
         <el-button size="small" type="primary">Clic para seleccionar archivo</el-button>
         <!--<div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>-->
       </el-upload>
       <el-input
         type="textarea"
         :rows="2"
         placeholder="Resultados"
         v-model="resultadoMasivo">
       </el-input>
       <span slot="footer" class="dialog-footer">
         <el-button @click="dgCambios = false">Cancelar</el-button>
         <el-button type="primary" @click="cambiosMasivos">Enviar</el-button>
       </span>
     </el-dialog>

   <el-dialog
      title="Re-Asignar Registros"
      :visible.sync="dgAsignar"
      width="30%">
        <div data-app>
          <v-select v-model="callCenter" :items="itemsCallCenter"
            :rules="[v => !!v || 'Seleccione un Call Center']"
            label="Call Center"
            item-value="i"
            item-text="d"
            required
          ></v-select>
          <p>Ingrese los Ids</p>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="Ids separados por coma (,)"
            v-model="idsReasignar"
            class="pb-4">
          </el-input>
          <div class="text-right">
            <el-button @click="dgAsignar = false">Cancelar</el-button>
            <el-button type="primary" @click="asignarRegistros">Asignar</el-button>
          </div>
        </div>
        </el-dialog>
        <el-dialog
          :title="currentClient"
          :visible.sync="dgDetalle"
          top="10px"
          width="50%">
            <div class="row">
                <div class="col-md-6 col-form-label text-center">
                  <img :src="currentImagen" alt="" height="500px" />
                </div>
                  <div class="col-md-6">
                      <div id="customSkinMap" class="map" ></div>
                  </div>
            </div>
        </el-dialog>
    <!-- end modals-->
    <!-- EDITAR CLIENTE -->
    <el-dialog
      :destroy-on-close="true"
      :visible.sync="showEdit"
      title="Editar Cliente"
      width="60%"
      top="20px"
      :before-close="cerrarNuevo"
      id="FormEditar"
      name="formEditar"
      index="form_editar"
      v-if="showEdit"
      >
      <div data-app>
        <v-form ref="editClient" v-model="editValid" lazy-validation >
          <div class="row no-gutters">
            <div class="col-2 pr-2">
              <v-text-field dense
                v-model="clEditar.nud" label="NUD Cliente"
                style="font-size:11px"
                required ></v-text-field>
            </div>
            <div class="col-4 pr-2">
              <v-text-field dense
                id="cl_edit-nombre"
                v-model="clEditar.nombre"
                style="font-size:11px"
                :rules="nameRules" label="Nombre" required
                @blur="v => cleanStringEdt(v.target.id, v.target.value)"
                ></v-text-field>
            </div>
          </div>
          <div class="row no-gutters">
              <div class="col-3 pr-2">
                <v-text-field dense
                  v-model="clEditar.telFijoU"
                  id="cl_edit-telCel"
                  style="font-size:11px"
                  :counter="10"
                  maxlength="10"
                  label="Teléfono 1 Fijo"
                  :error-messages="tlDupCl"
                  @blur="v => cleanNumber(v.target.id, v.target.value)"
                  ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <v-text-field dense
                  id="cl_edit-telFijo"
                  v-model="clEditar.telCelD"
                  style="font-size:11px"
                  :counter="10"
                  maxlength="10"
                  :error-messages="tlDup"
                  @blur="v => cleanNumber(v.target.id, v.target.value)"
                  label="Teléfono 2 Celular"  ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <v-text-field dense
                  v-model="clEditar.telFijoT"
                  id="cl_edit-telCel"
                  style="font-size:11px"
                  :counter="10"
                  maxlength="10"
                  label="Teléfono 3 Fijo"
                  :error-messages="tlDupCl"
                  @blur="v => cleanNumber(v.target.id, v.target.value)"
                  ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <v-text-field dense
                  id="cl_edit-telFijo"
                  v-model="clEditar.telCelC"
                  style="font-size:11px"
                  :counter="10"
                  maxlength="10"
                  :error-messages="tlDup"
                  @blur="v => cleanNumber(v.target.id, v.target.value)"
                  label="Teléfono 4 Celular"  ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-10 pr-2">
                <v-text-field dense
                  v-model="clEditar.direccion"
                  id="cl_edit-direccion"
                  style="font-size:11px"
                  :rules="[v => !!v || 'Escriba una dirección']"
                  @blur="v => cleanStringEdt(v.target.id, v.target.value)"
                  label="Dirección" required ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-5 pr-2">
                <v-text-field dense
                  v-model="clEditar.contacto"
                  style="font-size:11px"
                  label="Contacto Adicional"  ></v-text-field>
              </div>
              <div class="col-5 pr-2">
                <v-text-field dense
                  v-model="clEditar.correo"
                  style="font-size:11px"
                  label="Correo"  ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-2 pr-2">
                <v-text-field dense
                  v-model="clEditar.nExt"
                  style="font-size:11px"
                  :rules="[v => !!v || 'Escriba Número']"
                  label="Num. Exterior" required ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-text-field dense
                  v-model="clEditar.nInt"
                  style="font-size:11px"
                  label="Num. Interior" ></v-text-field>
              </div>
              <div class="col-4 pr-2">
                <v-text-field dense
                  v-model="clEditar.colonia"
                  id="cl_nuevo-colonia"
                  style="font-size:11px"
                  :rules="[v => !!v  || 'Escriba una colonia']"
                  @blur="v => cleanString(v.target.id, v.target.value)"
                  label="Colonia" required ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-text-field dense
                  v-model="clEditar.cp"
                  id="cl_edit-cp"
                  style="font-size:11px"
                  :rules="rulesCp"
                  @blur="v => cleanNumberEdt(v.target.id, v.target.value)"
                  label="CP" required ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-5 pr-2">
                <v-text-field dense
                  v-model="clEditar.calle1"
                  id="cl_edit-calle1"
                  style="font-size:11px"
                  :rules="[v => !!v  || 'Escriba una calle']"
                  @blur="v => cleanStringEdt(v.target.id, v.target.value)"
                  label="Calle 1" required ></v-text-field>
              </div>
              <div class="col-5 pr-2">
                <v-text-field dense
                  v-model="clEditar.calle2"
                  id="cl_edit-calle2"
                  style="font-size:11px"
                  :rules="[v => !!v  || 'Escriba una calle']"
                  @blur="v => cleanStringEdt(v.target.id, v.target.value)"
                  label="Calle 2" required ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-10 pr-2">
                <v-text-field dense
                  v-model="clEditar.comentario"
                  style="font-size:11px"
                  label="Comentario"  ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters">
            <div class="col-3 pr-2">
                <v-select v-model="clEditar.estatus" :items="itemsStatusEdt"
                    :rules="[v => !!v  || 'Sel... Estatus']"
                    label="Estatus"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                  ></v-select>
            </div>
              <div class="col-3 pr-2">
                <v-select v-model="clEditar.validado" :items="itemsGenEdt"
                    :rules="[v => !!v || 'Sel... Opción']"
                    label="Validado"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                  ></v-select>
              </div>
              <div class="col-3 pr-2">
                <v-select v-model="clEditar.categoria" :items="itemsCatEdt"
                    :rules="[v => !!v || 'Sel... Categoría']"
                    label="Categoría"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    :required="disRazonEdt"
                    dense
                    :disabled="disRazonEdt"
                  ></v-select>
              </div>
          </div>
              <div class="row">
                <div class="col-md-12 mt-3 text-center">
                  <el-button type="primary" @click="validaActualizar" >Actualizar Información</el-button>
                </div>
              </div>
            </v-form>
      </div>
    </el-dialog>
    <!-- END EDITAR CLIENT -->
    <v-overlay :value="overlay" z-index="2002">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { TimeSelect,
          DatePicker,
          Select,
          Option,
          Button,
          Dropdown, DropdownMenu, DropdownItem, Switch, Upload, Dialog, FormItem, Form } from 'element-ui';
import axios from 'axios';

import { Loader, LoaderOptions } from "google-maps";
const loader = new Loader("AIzaSyDnsLdCKdxoFbJMxVfZHMDnr_SB-L-T7nA");

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [FormItem.name]: FormItem,
    [Form.name]: Form
  },
  data() {
    return {
      overlay: false,
      uid: null,
      hash: null,
      lvl: null,
      loading: true,
      region: null,
      regiones: [
      ],
      gerencia: null,
      gerencias: [
      ],
      cedi: null,
      cedis: [
      ],
      usuario: null,
      usuarios: [],
      pre: null,
      preventas: [
        ],
      nuevos: [
      { i: -1, d: 'Todos' },
      { i:  0, d: 'Cartera' },
      { i:  1, d: 'Nuevos' }
        ],
      validado: null,
      validados: [
      { i: -1, d: 'Todos' },
      { i:  0, d: 'No Validado' },
      { i:  1, d: 'Validado' }
      ],
      estatus: null,
      status: [
        { i: -1, d: 'Todos' },
        { i:  1, d: 'Alta' },
        { i:  3, d: 'Transformados' },
        { i:  2, d: 'Baja' },
        { i:  4, d: 'Pendiente' },
        { i:  5, d: 'inconsistencia' },
        { i:  6, d: 'Progreso' },
        { i:  7, d: 'Programado' },
        { i:  8, d: 'Pendiente-Transformado'}
      ],
      itemsStatusEdt: [
        { i:  1, d: 'Alta' },
        { i:  2, d: 'Baja' },
        { i:  3, d: 'Transformado' },
        { i:  4, d: 'Pendiente' },
        { i:  5, d: 'Inconsistencia' },
        { i:  6, d: 'Progreso' }
      ],
      frecuencia: null,
      frecuencias: [
        { i: '-1',   d: 'Todos' },
        { i:  'L,J', d: 'L,J' },
        { i:  'M,V', d: 'M,V' },
        { i:  'X,S',d: 'X,S' }
      ],
      dateTimePicker: [],
      desserts: [],
      headers: [
        { text: '', value: 'acciones', width: 15, sortable: false },
        { text: 'ID', value: 'valCar_id', width: 50,  sortable: false },
        { text: 'Cartera ID', value: 'car_id', width: 100,  sortable: false },
        { text: 'Promotor', value: 'valCar_uid', width: 150,  sortable: false },
        { text: 'Call Center Asignado', value: 'valCar_call_center_uid', width: 200,  sortable: false },
        { text: '# Empleado', value: 'valCar_numero_empleado', width: 120,  sortable: false },

        { text: 'Region', value: 'valCar_reg', width: 150,  sortable: false },
        { text: 'Gerencia', value: 'valCar_gerencia', width: 150,  sortable: false },
        { text: 'Cedis', value: 'valCar_cds', width: 150,  sortable: false },
        { text: 'Estatus Visita', value: 'valCar_status_abrio', width: 50,  sortable: false },
        { text: 'Estatus validacion', value: 'valCar_val_status', width: 50,  sortable: false },
        { text: 'Con. Vistas', value: 'car_visitar', width: 100,  sortable: false },
        { text: 'Con. Pendientes', value: 'valCar_contador_pendientes', width: 100,  sortable: false },
        { text: 'Alta', value: 'car_nuevo', width: 70,  sortable: false },
        { text: 'Razon Baja', value: 'valCar_razon_baja', width: 200,  sortable: false },
        { text: 'Razon No acepta televenta', value: 'car_mot_inc', width: 150,  sortable: false },
        { text: 'Intentos llamada', value: 'valCar_intentos', width: 100,  sortable: false },
        { text: 'Ruta', value: 'valCar_rt_prev', width: 70,  sortable: false },
        { text: 'NUD', value: 'valCar_NUD'   , width: 50,  sortable: false },
        { text: 'Nombre', value: 'valCar_nombre', width: 200,  sortable: false },
        { text: 'Direccion', value: 'valCar_dir', width: 250,  sortable: false },
        { text: 'Num. Ext.', value: 'valCar_num_ext', width: 70,  sortable: false },
        { text: 'Num. Int.', value: 'valCar_num_int', width: 70,  sortable: false },
        { text: 'Colonia', value: 'valCar_col', width: 200,  sortable: false },
        { text: 'Calle 1', value:  'valCar_cll_1', width: 200,  sortable: false },
        { text: 'Calle 2', value: 'valCar_cll_2', width: 200,  sortable: false },
        { text: 'Manzana', value: 'car_manzana', width: 80,  sortable: false },
        { text: 'Lote', value: 'car_lote', width: 50,  sortable: false },
        { text: 'Local', value: 'car_local', width: 50,  sortable: false },
        { text: 'C.P.', value: 'valCar_cp', width: 50,  sortable: false },
        { text: 'Frecuecia', value: 'valCar_frec_vis', width: 50,  sortable: false },
        { text: 'Categoria', value: 'valCar_suscripcion', width: 50,  sortable: false },
        { text: 'Telefono 1 Celular', value: 'valCar_tel_mot', width: 100,  sortable: false },
        { text: 'Telefono 2 Celular', value: 'valCar_tel_int', width: 100,  sortable: false },
        { text: 'Telefono 3 Fijo', value: 'valCar_tel_3', width: 100,  sortable: false },
        { text: 'Telefono 4 Fijo', value: 'valCar_tel_4', width: 100,  sortable: false },
        { text: 'Contacto Adicional', value: 'valCar_contacto_adicional', width: 100,  sortable: false },

        //nuevo campos
        { text: 'Notas', value: 'valCar_notas', width: 200,  sortable: false },
        { text: 'NUD Duplicado', value: 'valCar_nud_duplicado', width: 200,  sortable: false },
        { text: 'Motivo Pendiente', value: 'valCar_motivo_pendiente', width: 200,  sortable: false },
        { text: 'Motivo Programado', value: 'valCar_motivo_programado', width: 150,  sortable: false },
        { text: 'Frecuencia de Programacion', value: 'valCar_frecuencia_1', width: 100,  sortable: false },
        { text: 'Cantidad de Garrafones 1', value: 'valCar_cantidad_garrafones_1', width: 150,  sortable: false },
        { text: 'Habito de consumo', value: 'valCar_sem_quin_men', width: 100,  sortable: false },
        { text: 'Frecuencia 2', value: 'valCar_frecuencia_2', width: 130,  sortable: false },
        { text: 'Cantidad de Garrafones 2', value: 'valCar_cantidad_garrafones_2', width: 150,  sortable: false },
        { text: 'Nombre del Responsable', value: 'valCar_nombre_responsable', width: 200,  sortable: false },
        { text: 'Extencion Responsable', value: 'valCar_extencion_responsable', width: 100,  sortable: false },
        { text: 'Hora Preferente Llamada', value: 'valCar_hora_preferente_llamada', width: 200,  sortable: false },
        { text: 'Foto Factura Anverso', value: 'valCar_photo_factura_anverso', width: 200,  sortable: false },
        { text: 'Foto Factura Reverso', value: 'valCar_photo_factura_reverso', width: 200,  sortable: false },
        //nuevos campos
        { text: 'Comentario', value: 'valCar_comments', width: 250,  sortable: false },
        { text: 'Giro', value: 'valCar_giro', width: 70,  sortable: false },
        { text: 'Correo', value: 'valCar_email', width: 100,  sortable: false },
        { text: 'Correo 2', value: 'valCar_email_2', width: 100,  sortable: false },
        { text: 'Factura', value: 'valCar_factura', width: 70,  sortable: false },
        { text: 'Foto 1', value: 'valCar_f1', width: 70,  sortable: false },
        { text: 'Firma', value: 'valCar_firma_digital', width: 70,  sortable: false },
        { text: 'GPS', value: 'valCar_gps', width: 250,  sortable: false },
        { text: 'CAMBIO GPS', value: 'valCar_bandera_gps', width: 80,  sortable: false },

        { text: 'Fecha Envio', value: 'valCar_upload_date', width: 120,  sortable: false },
        { text: 'Hora Envio', value: 'valCar_upload_time', width: 100,  sortable: false },
        { text: 'Archivo', value: 'valCar_bk', width: 150,  sortable: false },
        { text: 'Fecha validacion', value: 'valCar_tel_rev_date', width: 70,  sortable: false },
        { text: 'Hora validacion', value: 'valCar_tel_rev_time', width: 70,  sortable: false },
        { text: 'Update Massive', value: 'valCar_ms_updt', width: 50,  sortable: false },
        { text: 'Revisado Gepp', value: 'valCar_gepp_rev', width: 150,  sortable: false },
        { text: 'Archivo', value: 'valCar_s3_bucket', width: 50,  sortable: false }
      ],
      itemsGenEdt: [
        { i:  0, d: 'NO' },
        { i:  1, d: 'SI' }
      ],
      itemsCatEdt: [
        { i:  1, d: 'Ecommerce' },
        { i:  3, d: 'Televenta' },
        { i:  4, d: 'Suscripcion WhatsApp' }, //SMS
        { i:  5, d: 'Programado' }
      ],
      pageCount: 0,
      start: 0,
      total: 0,
      itemsT: 0,
      pagination: {
        page: 1,
        limit: 13,
        itemsPerPage: 13,
        currentPage: 1
      },
      dgFiltrar: false,
      idsFiltrar: null,
      dgActivar: false,
      clNuevo: {
        name: '',
        phone: '',
        phone2: '',
        folio: '',
        oper: 1,
        region: '',
        gerencia: '',
        cedis: '',
        ruta: ''
      },
      nuevoValid: true,
      nameRules: [
        v => !!v || 'Nombre Requerido'
      ],
      folioRules: [
        v => !!v || 'Folio Requerido'
      ],
      phoneRules: [
        v => (v && v.length >= 10) || 'Escriba un Número valido',
      ],
      dgCambios: false,
      dgAsignar: false,
      //nwRegion: '',
      itemsRegiones: [],
      itmRegion: null,
      itemsGerencias: [],
      itmGerencia: null,
      itemsCedis: [],
      itmCedis: null,
      itemsRutas: [],
      itmRuta: null,
      resultadoMasivo: '',
      callCenter: '',
      itemsCallCenter: [],
      masvisoFileXls: null,
      dgDetalle: false,
      currentClient: '',
      currentImagen: '',
      currentImagenFirma: '',
      permisos: {},
      nudsFiltrar: null,
      dgFiltrarNuds: false,
      idsReasignar: null,
      clEditar: {},
      showEdit: false
    }
  },
  methods: {
    handleCommand(cm){
      switch (cm){
        case 'descarga':
          this.descargaFiltro()
        break
        case 'descargaFotos':
          this.descargaFotos()
        break
        case 'activa':
          this.itmRegion = null
          this.itmGerencia = null
          this.itmCedis = null
          this.dgActivar = true
          this.getRegiones(1)
          this.$refs.frActiva.reset()
        break
        case 'filtrar':
          this.dgFiltrar = true
        break
        case 'filtrarNuds':
          this.dgFiltrarNuds = true
        break
        case 'cambiar':
          this.dgCambios = true
        break
        case 'asignar':
          this.getCallCenters()
          this.dgAsignar = true
        break
      }
    },
    rowClass(item) {
      //console.log(item);
      if(item.valCar_val_status == 'Transformado') { return 'table-trans' }
      if(item.valCar_val_status == 'Alta') { return 'table-alt' }
      if(item.valCar_val_status == 'Baja') { return 'table-baj' }
      if(item.valCar_val_status == 'Pendiente') { return 'table-pen' }
      if(item.valCar_val_status == 'Inconsistencia') { return 'table-inco' }
      if(item.valCar_val_status == 'Progreso') { return 'table-pro' }
      {
        return 'table-xxx'
      }
    },
    newPagina(p){
      // console.log(p);
      this.pagination.page = p
      this.start = this.pagination.limit * (p - 1)
      this.pagination.currentPage = p

      this.getData()
    },
    filtrar() {
      this.idsFiltrar = null
      this.nudsFiltrar = null
      this.pagination.page = 1
      this.start = 0
      this.getData()
    },
    getData() {
      //console.log(this.pre.join(','))
      this.overlay = true
      let json ={
        c: "carteraFin",
        cmd: "getCartFinData",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        lvl: this.lvl,
        //ip: "189.167.28.167",
        opr: 0,
        pId: 0, //Portal
        u_reg_id: this.region,
        ger_id: this.gerencia,
        cds_id: this.cedi,
        rta_id: this.pre ? this.pre.join(',') : '',
        sts_id: this.estatus,
        valid: this.validado,
        frecid: this.frecuencia,
        userCom: this.usuario ? this.usuario.join(',') : '',
        page: this.pagination.page,
        start: this.start,
        limit: this.pagination.limit,
        //date_fr: this.dateTimePicker[0],
        //date_to: this.dateTimePicker[1]
      };

      if(this.idsFiltrar){
        json.ids = this.idsFiltrar
        this.dgFiltrar = false
      }else if(this.nudsFiltrar){
        json.nuds = this.nudsFiltrar
        this.dgFiltrarNuds = false
      }else{
        json.date_fr = this.dateTimePicker[0]
        json.date_to = this.dateTimePicker[1]
      }


      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data);
        var resp = data.data.rows
        this.desserts = resp;
        this.pageCount = Math.ceil(data.data.results / this.pagination.limit)
        this.total = data.data.results
        this.itemsT = resp.length
        this.overlay = false
        //this.pagination.rowsTotal = data.data.results
        //this.isBusy = false
      })
    },
    getRegiones(sc){
      let json ={
        c: "geppValida",
        cmd: "getRegionesJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{

        if(sc == 0){
          this.regiones = data.data
          //
          this.regiones.unshift({ i: -1, d: 'TODAS' })
          //this.itemsRegiones.splice(0,1)
        }else if(sc == 1){
          this.itemsRegiones = data.data
        }
        
      })
    },
    getGerencias(sc){

      if(this.region == -1){
        this.gerencia = null
        this.cedi = null
        this.pre = null
        this.gerencias = []
        this.cedis = []
        this.preventas = []
        this.usuarios = []

        return false
      }

      let region = ''
      if(sc == 0){
        region = this.region
      }else if(sc == 1){
        region = this.itmRegion
      }

      let json ={
        c: "geppValida",
        cmd: "getGerenciasJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        reg: region
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.gerencias = data.data
          this.gerencias.unshift({ i: -1, d: 'TODAS' })
        }else if(sc == 1){
          this.itemsGerencias = data.data
        }
      })
    },
    getCedis(sc){

      let gerencia = ''
      if(sc == 0){
        gerencia = this.gerencia
      }else if(sc == 1){
        gerencia = this.itmGerencia
      }

      let json ={
        c: "geppValida",
        cmd: "getCedisJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        ger: gerencia
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.cedis = data.data
          this.cedis.unshift({ i: -1, d: 'TODOS' })
        }else if(sc == 1){
          this.itemsCedis = data.data
        }
      })
    },
    getPreventa(sc){
      let cedi = ''
      if(sc == 0){
        this.getUsuarios()
        cedi = this.cedi
      }else if(sc == 1){
        cedi = this.itmCedis
      }

      let json ={
        c: "geppValida",
        cmd: "getRtPrevJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        cds: cedi
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc==0) { 
        this.preventas = data.data
        this.preventas.unshift({ i: '-1', d: 'TODAS' })
        }else if(sc == 1) {
          this.itemsRutas = data.data
        }
      })
    },
    getUsuarios(){
      let json ={
        c: "geppValida",
        cmd: "getUserJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        cds: this.cedi,
        date_fr: this.dateTimePicker[0],
        date_to: this.dateTimePicker[1]
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data.data);
        this.usuarios = data.data
        this.usuarios.unshift({ i: -1, d: 'TODOS' })
      })
    },
    descargaFiltro(){
      var str = ''
      str += this.region ? "&u_reg_id="+this.region : ''
      str += this.gerencia ? "&ger_id="+this.gerencia : ''
      str += this.cedi ? "&cds_id="+this.cedi : ''
      str += this.pre && this.pre != '' ? "&rta_id="+this.pre.join(',') : ''
      str += this.estatus ? "&sts_id="+this.estatus : ''
      str += this.validado ? "&valid="+this.validado : ''
      str += this.frecuencia ? "&frecid="+this.frecuencia : ''
      str += this.dateTimePicker[0] ? "&date_fr="+this.dateTimePicker[0] : ''
      str += this.dateTimePicker[1] ? "&date_to="+this.dateTimePicker[1] : ''

      window.location = this.$api_url+"?c=carteraFin&cmd=getCartFinDataexportlist&exec=self&uid="+this.uid+"&hash="+this.hash+"&lvl="+this.lvl+"&opr=0"+str+"&pId=0";
    },
    filtrarRegistros(){
      this.pagination.page = 1
      this.start = 0
      this.getData()
    },
    verificaTel(){
      var respuesta = new Promise(resolve =>{
        let json ={
          c: "CarteraFin",
          cmd: "verifiTel",
          exec: "self",
          uid: this.uid,
          hash: this.hash,
          Tel: this.clNuevo.phone
        };

        axios.get(this.$api_url, {params: json})
        .then(data=>{
          console.log(data.data);
          if(data.data.success) {
            resolve(true)
          }else {
            resolve(false)
          }
        })
      })
      return respuesta
    },
    async crearNuevo(){
      if(this.$refs.nwActivar.validate()){
        var val = await this.verificaTel()
        if(val){
          let json = {
          usuarioid: this.uid,
          data: [
              {
                  a1: this.clNuevo.name,
                  a7: this.clNuevo.phone,//tel,
                  a8: this.clNuevo.phone2,//tel2,
                  h0: "3",
                  h4: this.clNuevo.folio,
                  region: this.clNuevo.region,
                  gerencia: this.clNuevo.gerencia,
                  cedi: this.clNuevo.cedis,
                  ruta: this.clNuevo.ruta
              }
              ]
          }
              // POST
            axios.post("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php?c=CarteraFin&cmd=validacionInsert&exec=self"
                        +"&hash="+this.hash+"&uid="+this.uid, JSON.stringify(json))
            .then(data=>{
              console.log(data.data);
              if(data.data.success) {
                  this.$notify({
                    title: '',
                    message: 'Clientes Registrado con éxito.',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'success',
                    timeout: 4000,
                    icon: 'tim-icons icon-bell-55'
                  });
              }else {
                  this.$notify({
                    title: 'Error',
                    message: 'El cliente no se registro de forma correcta.',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'warning',
                    timeout: 4000,
                    icon: 'tim-icons icon-bell-55'
                  });
              }
            })
        }else{
          this.$notify({
            title: 'Error',
            message: 'Se ha superado el número de registros.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
        }
      }
    },
    cambiosMasivos(){
      this.overlay = true
      this.pagination.page = 1
      this.start = 0
      const formData = new FormData();
      formData.append('wnd_upld_xls', this.masvisoFileXls);
      formData.append('wnd_upld_act', 2);
      formData.append('wnd_upld_uid', this.uid);

      var strl = '?'
        strl += "c=CarteraFin_all"
        strl += "&cmd=processForm"
        strl += "&exec=self"
        strl += "&uid="+this.uid
        strl += "&hash="+this.hash
      
      axios.post(this.$api_url+strl, formData)
      .then(data=>{
        this.overlay = false
        this.getData()
        if(data.data.success){
          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
        }else{
	          this.$notify({
              title: '',
              message: 'Error al cargar el archivo.',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'warning',
              timeout: 4000,
              icon: 'tim-icons icon-bell-55'
            });
        }
      })
    },
    masivoArchivo(file){
      this.masvisoFileXls = file.raw
    },
    getCallCenters(){
      let json ={
        c: "CallCenterRefresco",
        cmd: "getCallCenterUsersJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };
     axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data.data);
        this.itemsCallCenter = data.data
      })
    },
    eventRow(record){
      console.log(record);
      this.currentClient = record.valCar_nombre
      this.dgDetalle = true
      this.currentImagen = "https://s3.amazonaws.com/tms-gepptransformacion/"+record.valCar_f1
      this.currentImagenFirma = "https://tms-capenh.s3.amazonaws.com/"+record.valCar_f2
      var latlng = record.valCar_gps.split(',')
      var lat = parseFloat(latlng[0])
      var lng = parseFloat(latlng[1])
      this.loadMapa(lat, lng)
    },
    loadMapa(lat, lng){
    loader.load().then(function(google) {

      // Custom Skin & Settings Map Begin
      const myLatlng2 = new google.maps.LatLng(lat, lng); //(23.6, -102.5);
      const mapOptions2 = {
        zoom: 10,
        center: myLatlng2,
        scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
        disableDefaultUI: true, // a way to quickly hide all controls
        zoomControl: true
      };

      // console.log(document.getElementById("customSkinMap"));
      const map2 = new google.maps.Map(
        document.getElementById("customSkinMap"),
        mapOptions2
      );

      const marker2 = new google.maps.Marker({
        position: myLatlng2,
        title: "Custom Skin & Settings Map!"
      });

      marker2.setMap(map2);
      // Custom Skin & Settings Map End
    });
    },
    descargaFotos(){
      var str = ''
      str += this.region ? "&u_reg_id="+this.region : '0'
      str += this.gerencia ? "&ger_id="+this.gerencia : '0'
      str += this.cedi ? "&cds_id="+this.cedi : '0'
      str += this.pre && this.pre != '' ? "&rta_id="+this.pre : '0'
      str += this.estatus ? "&sts_id="+this.estatus : '0'
      str += this.validado ? "&valid="+this.validado : '0'
      str += this.frecuencia ? "&frecid="+this.frecuencia : '0'
      str += this.dateTimePicker[0] ? "&date_fr="+this.dateTimePicker[0] : ''
      str += this.dateTimePicker[1] ? "&date_to="+this.dateTimePicker[1] : ''
      
      window.location = this.$api_url+"?c=carteraFin&cmd=DownloadFotos&exec=self&uid="+this.uid+"&hash="+this.hash+"&lvl="+this.lvl+"&opr_id=0"+str+"&pId=0";
      
    },
    activaFrecuencias(){
      this.overlay = true
      let json ={
        c: "CarteraFin",
        cmd: "activarFrec",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        id: this.idsEliminar,
        frm_fin_reg: this.itmRegion,
        frm_fin_ger: this.itmGerencia,
        frm_fin_cds: this.itmCedis,
        rts: this.itmRuta ? this.itmRuta.join(',') : '',
        con: 1
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        this.overlay = false
        this.dgActivar = false
        this.pagination.page = 1
        this.start = 0
        this.getData()

          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    asignarRegistros(){
      this.overlay = true
      let json ={
        c: "CarteraFin",
        cmd: "_reAsignarCallCenter",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        idReasign: this.idsReasignar,
        CallId: this.callCenter
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        this.overlay = false
        this.dgActivar = false
        this.pagination.page = 1
        this.start = 0
        this.getData()

          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    actualizarCliente(){

      this.overlay = true
      let json = {
        c: "carteraFin",
        cmd: "sumbitChanges",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        vid: this.valCarId,
        chn: JSON.stringify(this.getAllDataEdt())
      };
      

      const queryString = new URLSearchParams(json).toString();

      axios.post(this.$api_url+'?'+queryString)
      .then(data=>{
        //console.log(data.data);
        this.overlay = false
        this.showEdit = false
          this.$notify({
            title: '',
            message: 'Se actualizó el registro con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });

          if(this.pagination.currentPage != 1){
            this.pagination.page = 1
            this.pagination.currentPage = 1
            this.start = 0
          }
        this.idsFiltrar = null
        this.nudsFiltrar = null
        this.getData()
        
      })

    },
    getAllDataEdt(){
      return {
          wnd_edt_nud: this.clEditar.nud ? this.clEditar.nud : '',
          wnd_edt_nme: this.clEditar.nombre ? this.clEditar.nombre : '',
          wnd_edt_tel: this.clEditar.telFijoU ?  this.clEditar.telFijoU : '',
          wnd_edt_tel_adi: this.clEditar.telCelD ?  this.clEditar.telCelD : '',
          wnd_edt_tel_3: this.clEditar.telFijoT ?  this.clEditar.telFijoT : '',
          wnd_edt_tel_4: this.clEditar.telCelC ?  this.clEditar.telCelC : '',
          wnd_edt_adiCon: this.clEditar.contacto ?  this.clEditar.contacto : '',
          wnd_edt_corrV: this.clEditar.correo ?  this.clEditar.correo : '',
          wnd_edt_dir: this.clEditar.direccion ?  this.clEditar.direccion : '',
          wnd_edt_next: this.clEditar.nExt ?  this.clEditar.nExt : '',
          wnd_edt_nint: this.clEditar.nInt ?  this.clEditar.nInt : '',
          wnd_edt_col: this.clEditar.colonia ?  this.clEditar.colonia : '',
          wnd_edt_cll1: this.clEditar.calle1 ?  this.clEditar.calle1 : '',
          wnd_edt_cll2: this.clEditar.calle2 ?  this.clEditar.calle2 : '',
          wnd_edt_cp: this.clEditar.cp ?  this.clEditar.cp: '',
          wnd_edt_com: this.clEditar.comentario ?  this.clEditar.comentario : '',
          wnd_status_sus: this.clEditar.estatus ?  this.clEditar.estatus : '',
          wnd_status_VAL: this.clEditar.validado ?  this.clEditar.validado : '',
          wnd_status_cat: this.clEditar.categoria ?  this.clEditar.categoria : ''
      }
    },
    eventDblRow(r){
      this.showEdit = true

      if(this.$refs.editClient){
        this.$refs.editClient.reset()
      }
      
          
      if(r.valCar_val_status && r.valCar_val_status != ''){
        var st = this.itemsStatusEdt.find(st => st.d.toLowerCase() ==  r.valCar_val_status.toLowerCase())
      }
      if(r.valCar_suscripcion && r.valCar_suscripcion != ''){
        var ct = this.itemsCatEdt.find(st => st.d.toLowerCase() ==  r.valCar_suscripcion.toLowerCase())
      }
 
      /*if(r.valCar_val_status && r.valCar_val_status != ''){
        var st = this.itemsStatusEdt.find(st => st.d.toLowerCase() ==  r.valCar_val_status.toLowerCase())
      }*/

      this.clEditar.nud = r.valCar_NUD
      this.clEditar.nombre = r.valCar_nombre
      this.clEditar.telFijoU = r.valCar_tel_mot
      this.clEditar.telCelD = r.valCar_tel_int
      this.clEditar.telFijoT = r.valCar_tel_3
      this.clEditar.telCelC = r.valCar_tel_4
      this.clEditar.contacto = r.valCar_contacto_adicional
      this.clEditar.correo = r.valCar_email
      this.clEditar.direccion = r.valCar_dir
      this.clEditar.nExt = r.valCar_num_ext
      this.clEditar.nInt = r.valCar_num_int
      this.clEditar.colonia = r.valCar_col
      this.clEditar.calle1 = r.valCar_cll_1
      this.clEditar.calle2 = r.valCar_cll_2
      this.clEditar.cp = r.valCar_cp
      this.clEditar.comentario = r.valCar_comments
      this.clEditar.estatus = st ? st.i : ''
      //this.clEditar.validado = ''
      this.clEditar.categoria = ct ? ct.i : ''

      this.valCarId = r.valCar_id

      //console.log(this.clEditar);
    },
    cleanStringEdt(id, val){

      console.log(id+' '+val);

      val = val.replace(/á/gi,"a");
      val = val.replace(/é/gi,"e");
      val = val.replace(/í/gi,"i");
      val = val.replace(/ó/gi,"o");
      val = val.replace(/ú/gi,"u");
      val = val.replace(/ñ/gi,"n");
      var tmpId = id.split('-')[1]
      
        this.clEditar[tmpId] =  val.trim()

    },
  },
  mounted () {
    if(localStorage.getItem('key')) {
      this.hash = localStorage.getItem('key')
      this.uid = localStorage.getItem('uid')
      this.lvl = localStorage.getItem('level')

      this.permisos = JSON.parse(localStorage.getItem('permission')).cartera_fin.actions

      var dat = new Date();
      var mo = (dat.getMonth() + 1) < 10 ? '0' + (dat.getMonth() + 1) : (dat.getMonth() + 1)
      var da = dat.getDate() < 10 ? '0' + dat.getDate() : dat.getDate()
      this.dateTimePicker[0] = dat.getFullYear() + '-' + mo + '-' + da
      this.dateTimePicker[1] = dat.getFullYear() + '-' + mo + '-' + da

      this.getRegiones(0)
      this.getData()
    } else {
       this.$router.push('login');
    }
  }
}
</script>
<style>
.v-btn {
  max-height: 20px;
  text-align: center;
}

.v-btn__content {
   font-size: 10px;
}

/*
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
   text-align: left;
   background-color: rgba(0, 0, 0, .05);
}
*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 11px !important;
  /* height: 22px;*/
}
/*
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}*/

.theme--light.v-pagination .v-pagination__item--active {
  background-color: #124A98;
  color: #fff;
}

  .map {
    height: 500px;
    width: 100%;
  }

.table-trans {
  background-color: #85C1E9;
}
.table-alt {
  background-color: #45B39D;
}
.table-baj {
  background-color: #F78181;
}
.table-pen {
  background-color: #C8A2C8;
}
.table-inco {
  background-color: #efef4c;
}
.table-pro {
  background-color: #C8A2C8;
}
.table-xxx {
  background-color: #fff;
}


</style>