<template>
    <div class="content" style="scroll: auto;">
          <div class="row">
            <div class="col-2">
              <el-dropdown style="width:100%" @command="handleCommand">
                <el-button type="primary" size="mini" style="width:100%">
                  Acciones<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <!--<el-dropdown-item command="callAsignar" v-if="permisos.add && lvl == 16">Asignar Registro</el-dropdown-item>-->
                  <el-dropdown-item command="crear" v-if="permisos.add">Crear Nuevo</el-dropdown-item>
                  <el-dropdown-item command="filtrarIds" v-if="permisos.add && lvl == 255" >Filtrar por ID's</el-dropdown-item>
                  <el-dropdown-item command="filtrarPhone" v-if="permisos.del">Buscar Cliente</el-dropdown-item>
                  <el-dropdown-item command="downDataVal" v-if="permisos.edit && lvl == 255 ">Descargar validados</el-dropdown-item>
                  <el-dropdown-item command="downDataNoVal" v-if="permisos.edit && lvl == 255 ">Descargar No validados</el-dropdown-item>
                  <el-dropdown-item command="downDataCasVal" v-if="permisos.edit">Descargar Cast validados</el-dropdown-item>
                  <el-dropdown-item command="downDataCasNoVal" v-if="permisos.edit && lvl == 255 ">Descargar Validados+NO</el-dropdown-item>
                  <el-dropdown-item command="asignarRegistros" v-if="permisos.edit && lvl == 255 ">Asignar Supervisor</el-dropdown-item>
                  <el-dropdown-item command="reSet" v-if="permisos.edit && lvl == 255">Reset Registros</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            Regiones:
              <el-select v-model="region" placeholder="REGIONES" size="mini" @change="getGerencias(0)">
                <el-option
                  v-for="item in regiones"
                  :key="item.i"
                  :label="item.d"
                  :value="item.i">
                </el-option>
              </el-select>
           
            
            <span v-if="gerencias.length != 0">Gerencias:</span>
              <el-select v-if="gerencias.length != 0" v-model="gerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(0)">
                <el-option
                  v-for="item in gerencias"
                  :key="item.i"
                  :label="item.d"
                  :value="item.i">
                </el-option>
              </el-select>
            
              <span v-if="cedis.length != 0">Cedis:</span>
              <el-select v-if="cedis.length != 0" v-model="cedi" placeholder="CEDIS" size="mini"  @change="getPreventa()" >
                <el-option
                  v-for="item in cedis"
                  :key="item.i"
                  :label="item.d"
                  :value="item.i">
                </el-option>
              </el-select>
            
              <span v-if="preventas.length != 0">Rutas:</span>
              <el-select v-if="preventas.length != 0" v-model="pre" placeholder="Rutas" size="mini">
                <el-option
                  v-for="item in preventas"
                  :key="item.i"
                  :label="item.d"
                  :value="item.i">
                </el-option>
              </el-select>
           
              Estatus:
              <el-select v-model="status" placeholder="ESTATUS" size="mini">
                <el-option
                  v-for="item in arrStatus"
                  :key="item.i"
                  :label="item.d"
                  :value="item.i">
                </el-option>
              </el-select>

              <!--Source:
              <el-select v-model="source" placeholder="SOURCE" size="mini">
                <el-option
                  v-for="item in sources"
                  :key="item.i"
                  :label="item.d"
                  :value="item.i">
                </el-option>
              </el-select>-->

              Fechas:
                <el-date-picker
                  type="daterange"
                  placeholder="Date Time Picker"
                  v-model="dateTimePicker"
                  size="mini"
                  format="dd-MM-yyyy"
                  value-format="yyyy-MM-dd"
                  prefix-icon="false"
                  class="el-input__icon"
                  range-separator="A"
                  style="font-size:8px; padding:3px 0px 3px 6px;"
                >
                </el-date-picker>
            
              &nbsp;<br/>
              <el-button size="mini" type="primary" style="width:100%"
              @click="filtrarData"
              >
                Filtrar
              </el-button>
        
            </div>
          <!--</div>

    <div class="row">-->
      <div class="col-10">
            <div class="div-flex">
              <div>Registros por asignar: <b> {{ porAsignar }}</b> </div>
            </div>
            <v-data-table
              dense
              :headers="headers"
              :items="desserts"
              item-key="car_id"
              class="elevation-1"
              :item-class="rowClass"
              :options="pagination"
              hide-default-footer
              single-select
              v-sortable-table="{onEnd:sortTheHeadersAndUpdateTheKey}"
            >
            <template v-slot:item.acciones="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="eventDblRow(item)"
                >
                  mdi-pencil
                </v-icon>
                </template>
              <template v-slot:item.valCar_val_status="{ item }" >
                {{
                  item.valCar_val_status == 1 ? 'ALTA'
                    : item.valCar_val_status == 2 ? 'BAJA'
                    : item.valCar_val_status == 3 ? 'TRANSFORMADO'
                    : item.valCar_val_status == 4 ? 'PENDIENTE'
                    : item.valCar_val_status == 5 ? 'INCONSISTENCIA'
                    : item.valCar_val_status == 6 ? 'EN PROGRESO' : '' }}
              </template>
              <template v-slot:item.valCar_suscripcion="{ item }" >
                {{
                  item.valCar_val_status == 1 ? 'E-commerce'
                    : item.valCar_val_status == 2 ? 'Suscripcion Televenta'
                    : item.valCar_val_status == 3 ? 'Televenta'
                    : item.valCar_val_status == 4 ? 'Suscripcion WhatsApp' : '' }}
              </template>
              <template v-slot:item.valCar_val_status="{ item }" >
                {{
                  item.valCar_val_status == 5 ? 'SÍ'
                    : 'NO' }}
              </template>
              <template v-slot:item.car_mot_inc="{ item }" >
                {{
                  item.car_mot_inc == 1 ? 'NO CONTESTO'
                    : item.car_mot_inc == 2 ? 'TELÉFONO INCORRECTO'
                    : item.car_mot_inc == 3 ? 'NO QUIERE TELEVENTA'
                    : item.car_mot_inc == 4 ? 'FUERA DE SERVICIO'
                    : item.car_mot_inc == 5 ? 'NO ES RESPONSABLE' : '' }}
              </template>
            </v-data-table>
            <div
              slot="footer"
              class="col-12 my-2 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  Registros <b>{{ start + 1 }}</b> A <b>{{ start + itemsT }}</b> de <b>{{ total }}</b>
                </p>
              </div>
              <b-pagination
                v-model="pagination.currentPage"
                :per-page="pagination.limit"
                :total-rows="total"
                size="sm"
                @change="newPagina"
                ></b-pagination>
            </div>
      </div>
    </div>

<!--------------------------->

    <v-overlay :value="overlay" z-index="2002">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>


    <!-- EDITAR CLIENTE -->
    <!-- EDITAR CLIENTE -->
    <el-dialog
      :visible.sync="showEdit"
      title="Editar Cliente"
      width="50%"
      top="20px"
      v-if="showEdit">
      <div data-app>
        <v-form ref="editClient" v-model="editValid" lazy-validation >
          <div class="row no-gutters">
            <div class="col-4" style="font-size:14px">
              <b>Nud Cliente: </b>{{ clEditar.nud }}
            </div>
            <div class="col-6 pb-0">
              <v-text-field dense
                id="cl_edit-nombre"
                v-model="clEditar.nombre"
                style="font-size:11px"
                :rules="nameRules" label="Nombre" required
                @blur="v => cleanStringEdt(v.target.id, v.target.value)"
                ></v-text-field>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-5 pr-2">
              <v-text-field dense
                id="cl_edit-nombre"
                v-model="clEditar.nombreR"
                style="font-size:11px"
                :rules="nameRules" label="Nombre R" required
                @blur="v => cleanStringEdt(v.target.id, v.target.value)"
                ></v-text-field>
            </div>
            <div class="col-5 pr-2">
              <v-text-field dense
                id="cl_edit-nombre"
                v-model="clEditar.extencionR"
                style="font-size:11px"
                :rules="nameRules" label="Extención R" required
                @blur="v => cleanStringEdt(v.target.id, v.target.value)"
                ></v-text-field>
            </div>
          </div>
          <div class="row no-gutters">
              <div class="col-3 pr-2">
                <v-text-field dense
                  v-model="clEditar.telFijoU"
                  id="cl_edit-telFijoU"
                  style="font-size:11px"
                  :counter="10"
                  maxlength="10"
                  @blur="v => cleanNumberEdt(v.target.id, v.target.value)"
                  label="Teléfono 1 fijo"  ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <v-text-field dense
                  v-model="clEditar.telCelD"
                  id="cl_edit-telCelD"
                  style="font-size:11px"
                  :counter="10"
                  maxlength="10"
                  label="Teléfono 2 celular"
                  @blur="v => cleanNumberEdt(v.target.id, v.target.value)"
                  ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <v-text-field dense
                  v-model="clEditar.telFijoT"
                  id="cl_edit-telFijoT"
                  style="font-size:11px"
                  :counter="10"
                  maxlength="10"
                  @blur="v => cleanNumberEdt(v.target.id, v.target.value)"
                  label="Teléfono 3 fijo"  ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <v-text-field dense
                  v-model="clEditar.telCelC"
                  id="cl_edit-telCelC"
                  style="font-size:11px"
                  :counter="10"
                  maxlength="10"
                  label="Teléfono 4 celular"
                  @blur="v => cleanNumberEdt(v.target.id, v.target.value)"
                  ></v-text-field>
              </div>
              <div class="col-6 pr-2">
                <v-text-field dense
                  v-model="clEditar.contacto"
                  style="font-size:11px"
                  label="Contacto Adicional"  ></v-text-field>
              </div>
              <div class="col-4 pr-2">
                <v-text-field dense
                  v-model="clEditar.correo"
                  style="font-size:11px"
                  label="Correo"  ></v-text-field>
              </div>
          </div>
            <div class="row no-gutters">
              <div class="col-8 pr-2">
                <v-text-field dense
                  v-model="clEditar.direccion"
                  id="cl_edit-direccion"
                  style="font-size:11px"
                  label="Dirección" required ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-text-field dense
                  v-model="clEditar.nExt"
                  style="font-size:11px"
                  :rules="[v => !!v || 'Escriba Número']"
                  label="Num. Exterior" required ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-text-field dense
                  v-model="clEditar.nInt"
                  style="font-size:11px"
                  label="Num. Interior" ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-3 pr-2">
                <v-text-field dense
                  v-model="clEditar.colonia"
                  id="cl_nuevo-colonia"
                  style="font-size:11px"
                  label="Colonia" required ></v-text-field>
              </div>
              <div class="col-4 pr-2">
                <v-text-field dense
                  v-model="clEditar.calle1"
                  id="cl_edit-calle1"
                  style="font-size:11px"
                  label="Calle 1" required ></v-text-field>
              </div>
              <div class="col-4 pr-2">
                <v-text-field dense
                  v-model="clEditar.calle2"
                  id="cl_edit-calle2"
                  style="font-size:11px"
                  label="Calle 2" required ></v-text-field>
              </div>
              <div class="col-1 pr-2">
                <v-text-field dense
                  v-model="clEditar.cp"
                  id="cl_edit-cp"
                  style="font-size:11px"
                  @blur="v => cleanNumberEdt(v.target.id, v.target.value)"
                  label="CP" required ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-4 pr-2">
                <v-select v-model="clEditar.intentos" :items="itemsIntentos"
                    label="Intentos Llamada"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    dense
                  ></v-select>
              </div>
              <div class="col-6 pr-2">
                <v-text-field dense
                  v-model="clEditar.notas"
                  style="font-size:11px"
                  label="Notas" ></v-text-field>
              </div>

          </div>
          <div class="row no-gutters">
              <div class="col-6 pr-2">
                <v-text-field dense
                  v-model="clEditar.comentario"
                  style="font-size:11px"
                  @blur="v => cleanNumberEdt(v.target.id, v.target.value)"
                  label="Comentario" required ></v-text-field>
              </div>
              <div class="col-6 pb-0">
                  <v-radio-group v-model="radioGroup" @change="valCheck" row>
                    <v-radio
                      label="Acepta Televenta"
                      value="0"
                    ></v-radio>
                    <v-radio
                      label="No Acepta Televenta"
                      value="1"
                    ></v-radio>
                  </v-radio-group>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-4 pr-2">
                <v-select v-model="clEditar.razon" :items="inconsistencias"
                    label="Razón"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                    :disabled="radioGroup !== '0'"
                  ></v-select>
              </div>
              <div class="col-4 pr-2">
                <v-select v-model="clEditar.estatus" :items="itemsStatusEdt"
                    label="Estatus"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                    @change="valStatus"
                  ></v-select>
              </div>
          </div>
              <div class="row no-gutters">
                <div class="col-md-12 mt-3 text-center">
                  <el-button type="primary" @click="actualizarCliente">Actualizar Información</el-button>
                </div>
              </div>
            </v-form>
      </div>
    </el-dialog>
    <!-- END EDITAR CLIENT -->
    <!-- END EDITAR CLIENT -->

    <el-dialog
      title="Reset Registros"
      :visible.sync="dgReset"
      width="30%">
      <el-upload
        class="upload-demo mb-3"
        :auto-upload="false"
        :on-change="resetArchivo"
        >
        <el-button size="small" type="primary">Clic para seleccionar archivo</el-button>
        <!--<div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>-->
      </el-upload>
      <!--<el-input
        type="textarea"
        :rows="2"
        placeholder="Resultados"
        v-model="resultadoMasivo">
      </el-input>-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dgReset = false">Cancelar</el-button>
        <el-button type="primary" @click="resetMasivos">Enviar</el-button>
      </span>
    </el-dialog>

    </div>
</template>

<script>

import { TimeSelect,
          DatePicker,
          Select,
          Option,
          Button,
          Dropdown, DropdownMenu, DropdownItem, Switch, Upload, Dialog } from 'element-ui';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import axios from 'axios';
import { Modal } from 'src/components';
// import { API_KEY } from "./API_KEY";
import { Loader, LoaderOptions } from "google-maps";

const loader = new Loader("AIzaSyDnsLdCKdxoFbJMxVfZHMDnr_SB-L-T7nA");

function setNewPos(parentNode, oldIndex, oldNode, newIndex, newNode) {
  setTimeout(() => {
    if (oldIndex < newIndex) {
      parentNode.insertBefore(oldNode, newNode.nextSibling);
    } else {
      parentNode.insertBefore(oldNode, newNode);
    }
  }, 150);
}


function watchClass(targetNode, classToWatch) {
  let lastClassState = targetNode.classList.contains(classToWatch);
  const observer = new MutationObserver((mutationsList) => {
    for (let i = 0; i < mutationsList.length; i++) {
      const mutation = mutationsList[i];
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        const currentClassState = mutation.target.classList.contains(classToWatch);
        if (lastClassState !== currentClassState) {
          lastClassState = currentClassState;
          if (!currentClassState) {
            mutation.target.classList.add('sortHandle');
          }
        }
      }
    }
  });
  observer.observe(targetNode, { attributes: true });
}

function sortTableRows(el) {
  const options = {
    handle: '.sortHandle',
    animation: 150,
    onStart: (evt) => {
      // Flag all cells in the column being dragged by adding a class to them
      el.querySelectorAll('tr').forEach((row) => {
        const draggedTd = row.querySelectorAll('td')[evt.oldIndex];
        if (draggedTd) {
          draggedTd.classList.add('sorting');
        }
      });
    },
    onEnd: (evt) => {
      // Take the dragged cells and move them over to the new column location
      el.querySelectorAll('tr').forEach((row) => {
        if (!row.querySelector('th')) {
          const oldNode = row.querySelector('.sorting');
          const newNode = row.querySelectorAll('td')[evt.newIndex];
          setNewPos(row, evt.oldIndex, oldNode, evt.newIndex, newNode);
          oldNode.classList.remove('sorting');
        }
      });
    },
  };
  const initEl = el.getElementsByTagName('thead')[0].getElementsByTagName('tr')[0];
  return Sortable.create(initEl, options);
}

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog
  },
  data() {
    return {
      overlay: false,
      headers: [
        { text: '', value: 'acciones', width: 15, sortable: false },
        { text: 'ID', value: 'valCar_id', width: 100,  sortable: false },
        { text: 'Cartera ID', value: 'car_id', width: 100,  sortable: false },
        { text: 'Estatus validación', value: 'valCar_val_status', width: 150,  sortable: false },
        { text: 'Categoría', value: 'valCar_suscripcion', width: 100,  sortable: false },
        { text: 'Horas de atención', value: 'valCar_hora_preferente_llamada', width: 150,  sortable: false },
        { text: 'Intentos Llamada', value: 'valCar_intentos', width: 100,  sortable: false },
        { text: 'Días Espera', value: 'valCar_val_days', width: 150,  sortable: false },
        { text: 'Inconsistencia', value: 'valCar_val_status', width: 150,  sortable: false },
        { text: 'Razon No acepta televenta', value: 'car_mot_inc', width: 150,  sortable: false },
        { text: 'Correo', value: 'valCar_email', width: 100,  sortable: false },
        { text: 'Notas', value: 'valCar_notas', width: 200,  sortable: false },
        { text: 'Comentario', value: 'valCar_comments', width: 250,  sortable: false, cellClass: 'truncate' },
        { text: 'Nombre', value: 'valCar_nombre', width: 250,  sortable: false },
        { text: 'Nombre R', value: 'valCar_nombre_responsable', width: 250,  sortable: false },
        { text: 'Extención R', value: 'valCar_extencion_responsable', width: 250,  sortable: false },
        { text: 'Dirección', value: 'valCar_dir', width: 250,  sortable: false },
        { text: 'Teléfono Fijo', value: 'valCar_tel_mot', width: 100,  sortable: false },
        { text: 'Teléfono 2 Celular', value: 'valCar_tel_int', width: 100,  sortable: false },
        { text: 'Teléfono 3 Fijo', value: 'valCar_tel_3', width: 100,  sortable: false },
        { text: 'Teléfono 4 Celular', value: 'valCar_tel_4', width: 100,  sortable: false },
        { text: 'Contacto Adicional', value: 'valCar_contacto_adicional', width: 250,  sortable: false },
        { text: 'NUD', value: 'valCar_NUD', width: 100,  sortable: false },
        { text: 'Ruta Preventa', value: 'valCar_rt_prev', width: 100,  sortable: false },
        { text: 'Frecuencia Visita', value: 'valCar_frec_vis', width: 150,  sortable: false },
        { text: 'Calle 1', value: 'valCar_cll_1', width: 200,  sortable: false },
        { text: 'Calle 2', value: 'valCar_cll_2', width: 200,  sortable: false },
        { text: 'Num. Ext.', value: 'valCar_num_ext', width: 100,  sortable: false },
        { text: 'Num. Int.', value: 'valCar_num_int', width: 100,  sortable: false },
        { text: 'C.P.', value: 'valCar_cp', width: 50,  sortable: false },
        { text: 'Giro', value: 'valCar_giro', width: 100,  sortable: false },
        { text: 'Colonia', value: 'valCar_col', width: 200,  sortable: false },
        { text: 'Operación', value: 'valCar_oper', width: 200,  sortable: false },
        { text: 'Región', value: 'valCar_reg', width: 150,  sortable: false },
        { text: 'Gerencia', value: 'valCar_gerencia', width: 150,  sortable: false },
        { text: 'Cedis', value: 'valCar_cds', width: 200,  sortable: false },
        { text: 'Resulta Incon', value: 'valCar_validado_incon', width: 150,  sortable: false },
        { text: 'Nuevo', value: 'car_nuevo', width: 150,  sortable: false },
        { text: 'Reset', value: 'valCar_reset', width: 100,  sortable: false }
        ],
      desserts: [],
      pageCount: 0,
      start: 0,
      total: 0,
      itemsT: 0,
      pagination: {
        page: 1,
        limit: 20,
        itemsPerPage: 20,
        currentPage: 1
      },
      pagMatch: {
        page: 1,
        start: 0,
        limit: 13,
        currentPage: 1,
        total: 0,
        itemsT: 0
      },
      isBusy: false,
      region: null,
      regiones: [],
      gerencia: null,
      gerencias: [
      ],
      cedi: null,
      cedis: [
      ],
      pre: null,
      preventas: [
        ],
      status: null,
      arrStatus: [
        { i: -1, d: 'Todos' },
        { i: 1, d: 'Alta' },
        { i: 2, d: 'Baja' },
        { i: 3, d: 'Transformado' },
        { i: 4, d: 'Pendiente' },
        { i: 5, d: 'Inconsistencia' },
        { i: 6, d: 'En Progreso' },
        { i: 7, d: 'Programado' },
        { i: 8, d: 'Pendiente-Transformado' }
        ],
    //dateTimePicker: [],
    hash: '',
    uid: '',
    lvl: '',
    porAsignar: 0,
    usuario: null,
    usuarios: [],
    dateTimePicker: [],
    dgBusca: false,
    matchTelefono: '',
    matchNombre: '',
    matchCalle: '',
    dgFilterIds: false,
    dgFilterPhone: false,
    dgReAsignar: false,
    idsFiltrar: null,
    phoneFilter: null,
    callCenter: null,
    itemsCallCenter: [],
    idsAsignar: null,
    dgAsignarReg: false,
    asignarRegDg: null,
    clNuevo: {
    },
    porAsignarData: 0,
    showAdd: false,
    nuevoValid: true,
    nameRules: [
      v => !!v || 'Nombre Requerido'
    ],
    phoneRules: [
        v => /^\d+$/.test(v)||'Solo Números'
      ],
    siRules: true,
    rulesCp: [
      v => (v && v.length == 5) || this.siRules || 'Escriba un Número valido',
      v => /^\d+$/.test(v) || this.siRules ||'Solo Números'
    ],
    itemsFrecuencia: [
      {i: 'L,J', d: 'Lu-Ju'},
      {i: 'M,V', d: 'Ma-Vi'},
      {i: 'X,S', d: 'Mi-Sa'}
    ],
    itemsFrecuenciaEdt: [
      {i: 'L,J', d: 'Lu-Ju'},
      {i: 'M,V', d: 'Ma-Vi'},
      {i: 'X,S', d: 'Mi-Sa'}
    ],
    itemsSources: [
      { i: 5, d: 'Whats' },
      { i: 4, d: 'Call Center' },
      { i: 6, d: 'Transfer' },
      { i: 7, d: 'Lona' }
    ],
    itemsForma: [
      { i: 1, d: 'Televenta' },
      { i: 2, d: 'Autoventa' }
    ],
    itemsComentarios: [
      { i: 'BAJO CONSUMO', d: 'BAJO CONSUMO' },
      /*{ i: 'NO ACEPTA MODELO', d: 'NO ACEPTA MODELO' },*/
      { i: 'NO ACEPTA MODELO TLV', d: 'NO ACEPTA MODELO TLV' },
      { i: 'COMPRA POR RELLENADORA', d: 'COMPRA POR RELLENADORA' },
      { i: 'RECHAZO POR NO CONTACTO', d: 'RECHAZO POR NO CONTACTO' },
      { i: 'CLIENTE YA EXISTE', d: 'CLIENTE YA EXISTE' },
      { i: 'NUM EQUIVOCADO (NO EXISTE)', d: 'NUM EQUIVOCADO (NO EXISTE)' },
      { i: 'CLIENTE INCORRECTO (No conocen a la persona)', d: 'CLIENTE INCORRECTO (No conocen a la persona)' },
      { i: 'BUZON DE VOZ', d: 'BUZON DE VOZ' },
      { i: 'FUERA DE COBERTURA', d: 'FUERA DE COBERTURA' },
      { i: 'CLIENTE DUPLICADO', d: 'CLIENTE DUPLICADO' },
      { i: 'Confirmado', d: 'Confirmado' },
      { i: 'PRE-VALIDADO', d: 'PRE-VALIDADO' }
    ],
    itemsComentariosEdt: [
      { i: 'BAJO CONSUMO', d: 'BAJO CONSUMO' },
      /*{ i: 'NO ACEPTA MODELO', d: 'NO ACEPTA MODELO' },*/
      { i: 'NO ACEPTA MODELO TLV', d: 'NO ACEPTA MODELO TLV' },
      { i: 'COMPRA POR RELLENADORA', d: 'COMPRA POR RELLENADORA' },
      { i: 'RECHAZO POR NO CONTACTO', d: 'RECHAZO POR NO CONTACTO' },
      { i: 'CLIENTE YA EXISTE', d: 'CLIENTE YA EXISTE' },
      { i: 'NUM EQUIVOCADO (NO EXISTE)', d: 'NUM EQUIVOCADO (NO EXISTE)' },
      { i: 'CLIENTE INCORRECTO (No conocen a la persona)', d: 'CLIENTE INCORRECTO (No conocen a la persona)' },
      { i: 'BUZON DE VOZ', d: 'BUZON DE VOZ' },
      { i: 'FUERA DE COBERTURA', d: 'FUERA DE COBERTURA' },
      { i: 'CLIENTE DUPLICADO', d: 'CLIENTE DUPLICADO' },
      { i: 'Confirmado', d: 'Confirmado' },
      { i: 'PRE-VALIDADO', d: 'PRE-VALIDADO' }
    ],
    aditionalComents: [
      {i: 'OTRO', d:'OTRO' },
      {i: 'NO QUIERE ESA FRECUENCIA', d:'NO QUIERE ESA FRECUENCIA' },
      {i: 'NO ACEPTA PRECIO', d:'NO ACEPTA PRECIO' },
      {i: 'NO ACEPTA DAR DATOS', d:'NO ACEPTA DAR DATOS' }
    ],
    aditionalComentsEdt: [
      {i: 'OTRO', d:'OTRO' },
      {i: 'NO QUIERE ESA FRECUENCIA', d:'NO QUIERE ESA FRECUENCIA' },
      {i: 'NO ACEPTA PRECIO', d:'NO ACEPTA PRECIO' },
      {i: 'NO ACEPTA DAR DATOS', d:'NO ACEPTA DAR DATOS' }
    ],
    itemsRazonEdt: [
      { i:  1, d: 'Bajo consumo' },
      { i:  2, d: 'No acepta modelo' },
      { i:  3, d: 'Llenadora' },
      { i:  5, d: 'Por no contacto' },
      { i:  6, d: 'Cliente ya existe' },
      { i:  7, d: 'Fuera de cobertura' },
      { i:  8, d: 'Cliente duplicado' }
    ],
    itemsRazon: [
      { i:  1, d: 'Bajo consumo' },
      { i:  2, d: 'No acepta modelo' },
      { i:  3, d: 'Llenadora' },
      { i:  5, d: 'Por no contacto' },
      { i:  6, d: 'Cliente ya existe' },
      { i:  7, d: 'Fuera de cobertura' },
      { i:  8, d: 'Cliente duplicado' }
    ],
    itemsStatus: [
      { i:  6, d: 'Pend. por validar' },
      { i:  2, d: 'Rechazado' },
      { i:  1, d: 'Validado' },
    ],
    itemsStatusEdt: [
      { i:  6, d: 'Progreso' },
      { i:  5, d: 'Inconsistencia' },
      { i:  1, d: 'Validado' },
    ],
    itemsIntentos: [
      { i: 1, d: 'Primer Intento' },
      { i: 2, d: 'Segundo Intento' },
      { i: 3, d: 'Tercer Intento' },
      { i: 4, d: 'Cuarto Intento' },
      { i: 5, d: 'Quinto Intento' }
    ],
    disOtro: true,
    disOtroEdt: true,
    disRazon: true,
    disRazonEdt: true,
    itemsRegiones: [],
    itemsGerencias: [],
    actGerencia: true,
    itemsCedis: [],
    showEdit: false,
    editValid: true,
    clEditar: {},
    itemsRegionesEdt: [],
    itemsGerenciasEdt: [],
    itemsCedisEdt:[],
    permisos: {},
    idInDB: 0,
    valCarId: '',
    carId: '',
    carNuevo: '',
    valCarIdRefresco: '',
    carIdRefresco: '',
    loadBus: false,
    asigSuper: {
      region: '',
      gerencia: '',
      cedi: '',
      usuario:  '',
      dateTime: '',
      asignar: 0
    },
    superRegiones: [],
    superGerencias: [],
    superCedis: [],
    maxim: 'col-6 px-0',
    actMax: false,
    tlDup: '',
    tlDupCl: '',
    siGuarda: true,
    dgReset: false,
    resetFile: null,
    radioGroup: "1",
    inconsistencias: []
    };
  },
  methods: {
    rowClass(item) {
      //if (!item) return
      if (item.valCar_val_days >= '1' ) return 'stanby'
      if (item.valCar_val_status == '1') return 'new'
      if (item.valCar_val_status == '2') return 'danger'
      if (item.valCar_val_status == '6') return 'pendint'
    },
    filtrarData(){
      if(this.pagination.currentPage != 1){
        this.pagination.page = 1
        this.pagination.currentPage = 1
        this.start = 0
      }
      this.phoneFilter = null
      this.idsFiltrar = null
      this.getData()
    },
    getData() {
      //console.log(this.pre.join(','))
      //this.isBusy = true
      this.overlay = true
      let json = {
        c: "callCenter",
        cmd: "getCallCenterData",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        lvl: this.lvl,
        opr: 0,
        u_reg_id: this.region,
        ger_id: this.gerencia,
        cds_id: this.cedi,
        sts: this.status,
        page: this.pagination.page,
        start: this.start,
        limit: this.pagination.limit,
        //ids: this.idsFiltrar
      };

      if(this.idsFiltrar){
        json.ids = this.idsFiltrar
      }else if(this.phoneFilter){
        json.find_number = this.phoneFilter
        this.dgFilterPhone = false
      }else{
        json.date_fr = this.dateTimePicker[0]
        json.date_to = this.dateTimePicker[1]
      }


      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data);
        this.overlay = false
        var resp = data.data.rows
        //this.items = resp;
        //this.pagination.rowsTotal = data.data.results

        this.desserts = resp;
        this.pageCount = Math.ceil(data.data.results / this.pagination.limit)
        this.total = data.data.results
        this.itemsT = resp.length
        //this.overlay = false
        //this.isBusy = false
        this.getAsignar(0)
      })
    },
    getRegiones(sc){

      let json ={
        c: "geppValida",
        cmd: "getRegionesJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{

        if(typeof(data.data.success) != "undefined" && !data.data.success){
            localStorage.clear()
            this.$router.push('login')
        }else{
          if(sc == 0){
            this.regiones = data.data
            //this.mdRegiones = data.data
            this.regiones.unshift({ i: -1, d: 'TODAS' })
            //this.mdRegiones.splice(0,1)
          }else if(sc == 1){
            this.itemsRegiones = data.data
          /*}else if(sc == 2){
            this.itemsRegionesEdt == data.data*/
          }else if(sc == 3){ //Re-asignar
            this.superRegiones = data.data
            this.superRegiones.unshift({ i: -1, d: 'TODAS' })
          }
        }
      })
    },
    getRegionesAs(){
      var respuesta = new Promise(resolve =>{
        let json ={
          c: "geppValida",
          cmd: "getRegionesJson",
          exec: "self",
          uid: this.uid,
          hash: this.hash
        };

        axios.get(this.$api_url, {params: json})
        .then(data=>{
          resolve(data.data)
        })
      })
      return respuesta
    },
    getGerencias(sc){
        this.gerencia = null
        this.cedi = null
        this.pre = null
      if(this.region == -1){
        this.gerencias = []
        this.cedis = []
        this.preventas = []
        return false
      }

      if(this.asigSuper && this.asigSuper.region == -1 && sc == 3){
        this.asigSuper.gerencia = null
        this.asigSuper.cedi = null
        this.superGerencias = []
        this.superCedis = []
        return false
      }
      
      var region = null

      if(sc == 0){
        region = this.region
      }else if(sc == 1){
        region = this.clNuevo.region
      }else if(sc == 2){
        region = this.clEditar.region
      }else if(sc == 3){
        region = this.asigSuper.region
      }
      
      let json ={
        c: "geppValida",
        cmd: "getGerenciasJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        reg:  region// this.region //? this.region : this.mdRegion ? this.mdRegion : null
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.gerencias = data.data
          this.gerencias.unshift({ i: -1, d: 'TODAS' })
        }else if(sc == 1){
          this.actGerencia = false
          this.itemsGerencias = data.data
        }else if(sc == 2){
          this.itemsCedisEdt = []
          this.clEditar.gerencia = null
          this.itemsGerenciasEdt = data.data
        }else if(sc == 3){
          //this.superCedis = []
          this.asigSuper.gerencia = null
          this.superGerencias = data.data
          this.superGerencias.unshift({ i: -1, d: 'TODAS' })
        }
      })
    },
    getGerenciasAs(region){
      var respuesta = new Promise(resolve =>{
        let json ={
          c: "geppValida",
          cmd: "getGerenciasJson",
          exec: "self",
          uid: this.uid,
          hash: this.hash,
          reg:  region// this.region //? this.region : this.mdRegion ? this.mdRegion : null
        };

        axios.get(this.$api_url, {params: json})
        .then(data=>{
          //console.log(data.data);
          resolve(data.data)
        })
      })
    return respuesta
    },
    getCedis(sc){
        this.cedi = null
        this.pre = null
      if(this.gerencia == -1){
        this.cedis = []
        this.preventas = []
        return false
      }

      if(this.asigSuper && this.asigSuper.gerencia == -1 && sc == 3){
        this.asigSuper.cedi = null
        this.superCedis = []
        return false
      }

      var gerencia = null

      if(sc == 0){
        gerencia = this.gerencia
      }else if(sc == 1){
        gerencia = this.clNuevo.gerencia
      }else if(sc == 2){
        gerencia = this.clEditar.gerencia
      }else if(sc == 3){
        gerencia = this.asigSuper.gerencia
      }

      let json ={
        c: "geppValida",
        cmd: "getCedisJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        ger: gerencia
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.cedis = data.data
          this.cedis.unshift({ i: -1, d: 'TODOS' })
        }else if(sc == 1){
          this.itemsCedis = data.data
        }else if(sc == 2){
          this.itemsCedisEdt = data.data
        }else if(sc == 3){
          this.asigSuper.cedi = null
          this.superCedis = data.data
          this.superCedis.unshift({ i: -1, d: 'TODOS' })
        }
      })
    },
    getCedisAs(ger){
      var respuesta = new Promise(resolve =>{
        let json ={
          c: "geppValida",
          cmd: "getCedisJson",
          exec: "self",
          uid: this.uid,
          hash: this.hash,
          ger: ger
        };

        axios.get(this.$api_url, {params: json})
        .then(data=>{
          //console.log(data.data);
          resolve(data.data)
        })
      })
      return respuesta

    },
    handleCommand(cm){
      switch (cm){
        case 'filtrarPhone':
          this.dgFilterPhone = true
          this.region = null
          this.gerencias = []
          this.gerencia = null
          this.cedis = []
          this.cedi = null
          this.status = null
          this.usuario = null
          if(this.pagination.currentPage != 1){
            this.pagination.page = 1
            this.pagination.currentPage = 1
            this.start = 0
            this.getData()
          }
        break
        case 'filtrarIds':
          this.dgFilterIds = true
          this.region = null
          this.gerencias = []
          this.gerencia = null
          this.cedis = []
          this.cedi = null
          this.status = null
          this.usuario = null
          if(this.pagination.currentPage != 1){
            this.pagination.page = 1
            this.pagination.currentPage = 1
            this.start = 0
            this.getData()
          }
        break
        case 'downDataVal':
          this.downloadDataVal(1, "getCallCenterDataDownload")
        break
        case 'downDataNoVal':
          this.downloadDataVal(0, "getCallCenterDataDownload")
        break
        case 'downDataCasVal':
          this.downloadDataVal(1, "getCallCenterDataDownloadCast")
        break
        case 'downDataCasNoVal':
          this.downloadDataVal(0, "getCallCenterDataAllDownload")
        break
        case 'reAsignar':
          this.getUsuarios(1)
          this.idsAsignar = '',
          this.callCenter = null
          this.dgReAsignar = true
        break
        case 'reSet':
          this.resetFile = null
          this.dgReset = true
        break
        case 'asignarRegistros':
          if(this.$refs.asignarReg){
            this.$refs.asignarReg.reset()
          }
          if(this.superRegiones.length == 0){
            this.getRegiones(3)
          }
          this.asigSuper.dateTime = this.dateTimePicker
          this.asigSuper.region = null
          this.asigSuper.gerencia = null
          this.superGerencias = []
          this.asigSuper.cedi = null
          this.superCedis = []
          this.asigSuper.usuario = null
          this.porAsignarData = 0
          this.asigSuper.asignar = 0
          this.dgAsignarReg = true
        break
        case 'crear':
          this.showAdd = true
          if(this.$refs.nwClient){
            this.$refs.nwClient.reset()
          }
          this.clNuevo = {}
          this.disOtro = true
          this.disRazon = true

          var idx = this.itemsRazon.findIndex(item => item.i == 9)
            if(idx != -1){
              this.itemsRazon.splice(idx, 1)
            }
          for(var a in this.aditionalComents){
            var idx = this.itemsComentarios.findIndex(item => item.i == this.aditionalComents[a].i)
            if(idx !== -1){
              this.itemsComentarios.splice(idx, 1)
            }
          }

          if(this.itemsRegiones.length == 0){
            this.getRegiones(1)
          }
         this.clNuevo.intLlamadas = 1
         this.siRules = true
         this.siGuarda = false

        break
        case 'callAsignar':

        break
      }
    },
    validaNumero(id, phone){

      if(phone == ""){
        return false
      }

      var ids = id.split('-')[1]
      if(ids == 'telFijo') this.tlDup = ""
      if(ids == 'telCel') this.tlDupCl = ""
      this.siGuarda = true

      let json ={
        c: "CallCenterNewCapen",
        cmd: "validatePhoneNumber",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        phoneNumber: phone
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        
        //this.$refs.cl_edit_telFijo.valid = false
        //console.log(this.$refs.cl_edit_telFijo);

        var resp = data.data

          if(resp.success){
            this.siGuarda = false
            if(ids == 'telFijo') this.tlDup = "Duplicado"
            if(ids == 'telCel') this.tlDupCl = "Duplicado"
            
            this.$notify({
              title: '',
              message: 'Registro Duplicado, Este número esta duplicado, ID: ' + resp.data['0'] + ' NUD: ' + resp.data['1'] + ' Nombre: ' + resp.data['2'],
              verticalAlign: 'top',
              horizontalAlign: 'center',
              type: 'success',
              timeout: 10000,
              icon: 'tim-icons icon-bell-55'
            });
          }
      })
    },
    downloadDataVal(val, cmd){
      let json ={
        c: "CallCenterNewCapen",
        cmd: cmd,
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        lvl: this.lvl,
        val: val,
        u_reg_id: this.region,
        ger_id: this.gerencia,
        cds_id: this.cedi,
        userFil: this.usuario,
        date_fr: this.dateTimePicker[0],
        date_to: this.dateTimePicker[1]
      };

      var url = new URLSearchParams(json).toString()

      //console.log(this.$api_url + '?' + url);

      window.location = this.$api_url + '?' + url

      /*axios.get(this.$api_url, {params: json})
      .then(data=>{
          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })*/
    },
    getUsuarios(sc){
      let json ={
        c: "CallCenterNewCapen",
        cmd: "getCallCenterUsersJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 1){
          this.itemsCallCenter = data.data
        }else{
          this.usuarios = data.data
          this.usuarios.unshift({ i: -1, d: 'TODOS' })
        }
      })
    },
    getAsignar(sc){
      
      let region = 0
      let gerencia = 0
      let cedi = 0
      let d1 = ''
      let d2 = ''
      if(sc == 0){
        region = this.region
        gerencia = this.gerencia
        cedi = this.cedi
        d1 = this.dateTimePicker[0]
        d2 = this.dateTimePicker[1]
      }else if(sc == 1){
        region = this.asigSuper.region ? this.asigSuper.region : 0
        gerencia = this.asigSuper.gerencia ? this.asigSuper.gerencia : 0
        cedi = this.asigSuper.cedi ? this.asigSuper.cedi : 0
        d1 = this.asigSuper.dateTime ? this.asigSuper.dateTime[0] : ''
        d2 = this.asigSuper.dateTime ? this.asigSuper.dateTime[1] : ''
      }

      let json ={
        c: "CallCenter",
        cmd: "restantes",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        u_reg_id: region,
        ger_id: gerencia,
        cds_id: cedi,
        date_fr: d1,
        date_to: d2
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        //
        if(sc == 1){
          this.porAsignarData = data.data
        }else{
          this.porAsignar = data.data
        }
      })
    },
    eventDblRow(r){
      this.showEdit = true

      if(this.$refs.editClient){
        this.$refs.editClient.reset()
      }

      /*if(r.valCar_val_status && r.valCar_val_status != ''){
        var st = this.itemsStatusEdt.find(st => st.d.toLowerCase() ==  r.valCar_val_status.toLowerCase())
      }*/
      if(r.car_mot_inc && r.car_mot_inc != ''){
        var ct = this.inconsistencias.find(st => st.i ==  r.car_mot_inc)
      }
 
      if(r.valCar_val_status && r.valCar_val_status != ''){
        var st = this.itemsStatusEdt.find(st => st.d.toLowerCase() ==  r.valCar_val_status.toLowerCase())
      }
      if(r.valCar_intentos && r.valCar_intentos != ''){
        var inte = parseInt(r.valCar_intentos) + 1
        var ll = this.itemsIntentos.find(ll => ll.i == inte)
      }

      this.clEditar.nud = r.valCar_NUD
      this.clEditar.nombre = r.valCar_nombre
      this.clEditar.nombreR = r.valCar_nombre_responsable
      this.clEditar.extencionR = r.valCar_extencion_responsable
      this.clEditar.telFijoU = r.valCar_tel_mot
      this.clEditar.telCelD = r.valCar_tel_int
      this.clEditar.telFijoT = r.valCar_tel_3
      this.clEditar.telCelC = r.valCar_tel_4
      this.clEditar.contacto = r.valCar_contacto_adicional
      this.clEditar.correo = r.valCar_email
      this.clEditar.direccion = r.valCar_dir
      this.clEditar.nExt = r.valCar_num_ext
      this.clEditar.nInt = r.valCar_num_int
      this.clEditar.colonia = r.valCar_col
      this.clEditar.calle1 = r.valCar_cll_1
      this.clEditar.calle2 = r.valCar_cll_2
      this.clEditar.cp = r.valCar_cp
      this.clEditar.comentario = r.valCar_comments
      this.clEditar.estatus = st ? st.i : ''
      this.clEditar.intentos = ll ? ll.i : this.itemsIntentos[4].i
      this.clEditar.notas = r.valCar_notas
      this.clEditar.razon = ct ? ct.i : ''

      this.valCarId = r.valCar_id
      this.carId = r.car_id
      this.carNuevo = r.car_nuevo

      //console.log(this.clEditar);
    },
    filterMatch(){
      this.overlay = true
      //console.log(record);
      let json ={
        c: "CarteraIniGenerico",
        cmd: "getCartFinData",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        valCar_nombre_2: this.matchNombre,
        valCar_tel_int_2: this.matchTelefono,
        valCar_tel_mot_2: this.matchTelefono,
        valCar_dir: this.matchCalle,
        page: this.pagMatch.page,
        start: this.pagMatch.start,
        limit: this.pagMatch.limit
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        var resp = data.data.rows
        //this.dgBusca = true
        this.overlay = false
        this.dessertsBus = data.data.rows
        this.pagMatch.total = data.data.results
        this.pagMatch.itemsT = resp.length
        
      })
    },
    reAsignar(){
      let json ={
        c: "CallCenterNewCapen",
        cmd: "_reAsignarCallCenter",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        idReasign: this.idsAsignar,
        CallId: this.callCenter
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
          if(this.pagination.currentPage != 1){
            this.pagination.page = 1
            this.pagination.currentPage = 1
            this.start = 0
          }

        this.getData()

        this.dgReAsignar = false
          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
        
      })
    },
    validaCrearNuevo(){
      if(this.$refs.nwClient.validate()){
        //console.log(this.clNuevo.comentario);
        /*if(this.clNuevo.estatus == 2 && this.clNuevo.intLlamadas < 3){
          this.$notify({
            title: '',
            message: 'No puedes rechazar hasta el 3er intento.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });
        }else*/
        if(this.clNuevo.estatus == 1 && this.clNuevo.comentario == 'Confirmado'){
          this.crearNuevo()
        }else if(this.clNuevo.estatus == 1 && this.clNuevo.comentario != 'Confirmado'){
          this.$notify({
            title: '',
            message: 'Validado solo acepta comentario Confirmado.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });
        }else if(this.clNuevo.estatus == 2 && this.clNuevo.comentario != 'Confirmado' && this.clNuevo.comentario != "OTRO"){
          this.crearNuevo()
        }else if(this.clNuevo.estatus == 2 && this.clNuevo.comentario != 'Confirmado' && this.clNuevo.comentario == "OTRO" ){
          this.clNuevo.comentario = this.clNuevo.otroComentario
          this.crearNuevo()
        }else if(this.clNuevo.estatus == 2 && this.clNuevo.comentario == 'Confirmado'){
          this.$notify({
            title: '',
            message: 'Estatus Rechazado no utiliza comentario Confirmado.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });
        }else if(this.clNuevo.estatus != 1 && this.clNuevo.estatus != 2){
          this.crearNuevo()
        }else{
          this.$notify({
            title: '',
            message: 'Error al guardar la información.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });
        }
      }
    },
    validaActualizar(){
      if(this.$refs.editClient.validate()){
        // console.log(this.clNuevo.comentario);
        /*if(this.clEditar.estatus == 2 && this.clEditar.intLlamadas < 3){
          this.$notify({
            title: '',
            message: 'No puedes rechazar hasta el 3er intento.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });
        }else*/
        if(this.clEditar.estatus == 1 && this.clEditar.comentario == 'Confirmado'){
          this.actualizarCliente()
        }else if(this.clEditar.estatus == 1 && this.clEditar.comentario != 'Confirmado'){
          this.$notify({
            title: '',
            message: 'Validado solo acepta comentario Confirmado.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });
        }else if(this.clEditar.estatus == 2 && this.clEditar.comentario != 'Confirmado' && this.clEditar.comentario != "OTRO"){
          this.actualizarCliente()
        }else if(this.clEditar.estatus == 2 && this.clEditar.comentario != 'Confirmado' && this.clEditar.comentario == "OTRO" ){
          this.clEditar.comentario = this.clEditar.otroComentario
          this.actualizarCliente()
        }else if(this.clEditar.estatus == 2 && this.clEditar.comentario == 'Confirmado'){
          this.$notify({
            title: '',
            message: 'Estatus Rechazado no utiliza comentario Confirmado.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });
        }else if(this.clEditar.estatus != 1 && this.clEditar.estatus != 2){
          this.actualizarCliente()
        }else{
          this.$notify({
            title: '',
            message: 'Error al guardar la información.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });
        }
      }
    },
    crearNuevo(){
      let json ={
        c: "CallCenterNewCapen",
        cmd: "sumbitChangesNewRegister",
        exec: "self",
        hash: this.hash,
        uid: this.uid,
        motIcn: this.clNuevo.razon ? this.clNuevo.razon : 0,
        stat: this.clNuevo.estatus,
        intentos: this.clNuevo.intLlamadas,
        chn: JSON.stringify(this.getAllData())
      }

      
      const queryString = new URLSearchParams(json).toString();
      //console.log(queryString);
      
      axios.post(this.$api_url+'?'+queryString)
      .then(data=>{
        //console.log(data.data);
        this.showAdd = false
          this.$notify({
            title: '',
            message: 'Se creo el registro con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });

          if(this.pagination.currentPage != 1){
            this.pagination.page = 1
            this.pagination.currentPage = 1
            this.start = 0
          }

        this.getData()
        
      })

    },
    actualizarCliente(){

      this.overlay = true
      let json = {
        c: "callCenter",
        cmd: "sumbitChanges",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        vid: this.valCarId,
        caid: this.carId,
        motIcn: this.clEditar.razon,
        stat: this.clEditar.estatus,
        nue: this.carNuevo,
        intentos: this.clEditar.intentos,
        tel_1: this.clEditar.telFijoU,
        tel_2: this.clEditar.telCelD,
        tel_3: this.clEditar.telFijoT,
        tel_4: this.clEditar.telCelC,
        chn: JSON.stringify(this.getAllDataEdt())
      };
      

      const queryString = new URLSearchParams(json).toString();

      axios.post(this.$api_url+'?'+queryString)
      .then(data=>{
        //console.log(data.data);
        this.overlay = false
        this.showEdit = false
          this.$notify({
            title: '',
            message: 'Se actualizó el registro con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });

          if(this.pagination.currentPage != 1){
            this.pagination.page = 1
            this.pagination.currentPage = 1
            this.start = 0
          }
        this.idsFiltrar = null
        this.nudsFiltrar = null
        this.getData()
        
      })

    },
    getAllData(){
      return {
            wnd_edt_nud_New: this.clNuevo.nud ? this.clNuevo.nud : '',
            wnd_edt_enc_volante_New: this.clNuevo.folio ? this.clNuevo.folio : '',
            wnd_edt_enc_lona_New: this.clNuevo.lona ? this.clNuevo.lona : '',
            wnd_edt_enc_internet_New: this.clNuevo.internet ? this.clNuevo.internet : '',
            wnd_edt_enc_otro_New: this.clNuevo.otro ? this.clNuevo.otro : '',
            wnd_edt_enc_garr_consume_New: this.clNuevo.garrConsumo ? this.clNuevo.garrConsumo :'',
            wnd_edt_enc_marca_consume_New: this.clNuevo.maraConsume ? this.clNuevo.maraConsume : '',
            wnd_edt_garr_mix_New: this.clNuevo.garrMix ? this.clNuevo.garrMix : '',
            wnd_edt_enc_paga_garr_New: this.clNuevo.garrPaga ? this.clNuevo.garrPaga : '',
            wnd_edt_enc_acep_precio_New: this.clNuevo.aceptPrecio ? this.clNuevo.aceptPrecio : '',
            wnd_edt_enc_consu_nues_New: this.clNuevo.consNuestro ? this.clNuevo.consNuestro : '',
            wnd_edt_enc_donde_com_New: this.clNuevo.garrDonde ? this.clNuevo.garrDonde : '',
            wnd_edt_nme_New: 'BC ' + this.clNuevo.nombre,
            wnd_edt_tel_New: this.clNuevo.telCel ?  this.clNuevo.telCel : '',
            wnd_edt_tel_adi_New: this.clNuevo.telFijo ? this.clNuevo.telFijo : '',
            wnd_edt_corrV_New: this.clNuevo.correo ? this.clNuevo.correo : '',
            wnd_edt_dir_New: this.clNuevo.direccion,
            wnd_edt_next_New: this.clNuevo.nExt,
            wnd_edt_nint_New: this.clNuevo.nInt ? this.clNuevo.nInt : '',
            wnd_edt_mza_New: this.clNuevo.manzana ? this.clNuevo.manzana : '',
            wnd_edt_lote_New: this.clNuevo.lote ? this.clNuevo.lote : '',
            wnd_edt_loc_New: this.clNuevo.local ? this.clNuevo.local : '',
            wnd_edt_col_New: this.clNuevo.colonia ? this.clNuevo.colonia : '',
            wnd_edt_cll1_New: this.clNuevo.calle1 ? this.clNuevo.calle1 : '',
            wnd_edt_cll2_New: this.clNuevo.calle2 ? this.clNuevo.calle2 : '',
            wnd_edt_cp_New: this.clNuevo.cp,
            wnd_edt_gps_new_lat: this.clNuevo.gpsX,
            wnd_edt_gps_new_lng: this.clNuevo.gpsY,
            wnd_edt_gps_new: this.clNuevo.gpsX + ','+ this.clNuevo.gpsY,
            wnd_edt_frec_New: this.clNuevo.frecuencia,
            wnd_edt_sourse_New: this.clNuevo.source,
            wnd_edt_forma_New: this.clNuevo.forma,
            wnd_edt_com_New: this.clNuevo.comentario,
            wnd_edt_reg_name_New: this.clNuevo.region,
            wnd_edt_ger_name_New: this.clNuevo.gerencia,
            wnd_edt_cds_name_New: this.clNuevo.cedis,
            wnd_edt_rtp_New: this.clNuevo.ruta ? this.clNuevo.ruta : '',
            wnd_status_sus_New: this.clNuevo.estatus,
            wnd_edt_irz_New: this.clNuevo.razon ? this.clNuevo.razon : ''
          }
    },
    getAllDataEdt(){
      return {
          wnd_edt_nud: this.clEditar.nud ? this.clEditar.nud : '',
          wnd_edt_nme: this.clEditar.nombre ? this.clEditar.nombre : '',
          wnd_edt_nme_r: this.clEditar.nombreR ? this.clEditar.nombreR : '',
          wnd_edt_ext_r: this.clEditar.extencionR ? this.clEditar.extencionR : '',
          wnd_edt_tel: this.clEditar.telFijoU ?  this.clEditar.telFijoU : '',
          wnd_edt_tel_adi: this.clEditar.telCelD ?  this.clEditar.telCelD : '',
          wnd_edt_tel_3: this.clEditar.telFijoT ?  this.clEditar.telFijoT : '',
          wnd_edt_tel_4: this.clEditar.telCelC ?  this.clEditar.telCelC : '',
          wnd_edt_adicional: this.clEditar.contacto ?  this.clEditar.contacto : '',
          wnd_edt_corrV: this.clEditar.correo ?  this.clEditar.correo : '',
          wnd_edt_dir: this.clEditar.direccion ?  this.clEditar.direccion : '',
          wnd_edt_next: this.clEditar.nExt ?  this.clEditar.nExt : '',
          wnd_edt_nint: this.clEditar.nInt ?  this.clEditar.nInt : '',
          wnd_edt_col: this.clEditar.colonia ?  this.clEditar.colonia : '',
          wnd_edt_cll1: this.clEditar.calle1 ?  this.clEditar.calle1 : '',
          wnd_edt_cll2: this.clEditar.calle2 ?  this.clEditar.calle2 : '',
          wnd_edt_cp: this.clEditar.cp ?  this.clEditar.cp: '',
          wnd_edt_com: this.clEditar.comentario ?  this.clEditar.comentario : '',
          wnd_edt_int: this.clEditar.intentos ?  this.clEditar.intentos : '',
          wnd_edt_notas: this.clEditar.notas ?  this.clEditar.notas : '',
          wnd_edt_inc: this.radioGroup ?  this.radioGroup : '',
          wnd_status_sus: this.clEditar.estatus ?  this.clEditar.estatus : '',
          wnd_edt_irz: this.clEditar.razon ?  this.clEditar.razon : ''
      }
    },
    asignarSuper(){
      this.overlay = true
      let json = {
        c: "CallCenterNewCapen",
        cmd: "asignarSUpervisor",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        u_reg_id: this.asigSuper.region ? this.asigSuper.region : null,
        ger_id: this.asigSuper.gerencia ? this.asigSuper.gerencia : null,
        cds_id: this.asigSuper.cedi ? this.asigSuper.cedi : null,
        date_fr: this.asigSuper.dateTime[0],
        date_to: this.asigSuper.dateTime[1],
        numasig: this.asigSuper.asignar ? this.asigSuper.asignar : 0,
        CallId: this.asigSuper.usuario
        //ids: this.idsFiltrar
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        this.overlay = false
            this.$notify({
              title: '',
              message: 'Se realizo la Asignación con éxito.',
              verticalAlign: 'top',
              horizontalAlign: 'center',
              type: 'success',
              timeout: 10000,
              icon: 'tim-icons icon-bell-55'
            });

            this.dgAsignarReg = false

          if(this.pagination.currentPage != 1){
            this.pagination.page = 1
            this.pagination.currentPage = 1
            this.start = 0
          }

        this.getData()

      })
    },
    cleanString(id, val){

      console.log(id+' '+val);

      val = val.replace(/á/gi,"a");
      val = val.replace(/é/gi,"e");
      val = val.replace(/í/gi,"i");
      val = val.replace(/ó/gi,"o");
      val = val.replace(/ú/gi,"u");
      val = val.replace(/ñ/gi,"n");

      val = val.replace(/Á/gi,"a");
      val = val.replace(/É/gi,"e");
      val = val.replace(/Í/gi,"i");
      val = val.replace(/Ó/gi,"o");
      val = val.replace(/Ú/gi,"u");
      val = val.replace(/Ñ/gi,"n");
      var tmpId = id.split('-')[1]
      
        this.clNuevo[tmpId] =  val.trim()
    },
    cleanStringEdt(id, val){

      console.log(id+' '+val);

      val = val.replace(/á/gi,"a");
      val = val.replace(/é/gi,"e");
      val = val.replace(/í/gi,"i");
      val = val.replace(/ó/gi,"o");
      val = val.replace(/ú/gi,"u");
      val = val.replace(/ñ/gi,"n");
      var tmpId = id.split('-')[1]
      
        this.clEditar[tmpId] =  val.trim()

    },
    cleanNumber(id, val){
      //return false
      //console.log(id);
      var tmpId = id.split('-')[1]
       val = val.replace(/[^0-9]/g,'')
       this.clNuevo[tmpId] = val
      this.validaNumero(id, val)
     
    },
    cleanNumberEdt(id, val){
      //return false
      var tmpId = id.split('-')[1]
       val = val.replace(/[^0-9]/g,'')
       this.clEditar[tmpId] = val
      this.validaNumero(id, val)
     
    },
    validaComent(val){
      var idx = this.itemsRazon.findIndex(item => item.i == 9)
      if (val === 'NO ACEPTA MODELO TLV') {
        this.itemsRazon.push({i: '9', d:'E-commerce' })
      }else if(idx != -1){
        this.itemsRazon.splice(idx, 1)
      }

      if(val == 'OTRO'){
        this.disOtro = false
      }else {
        this.clNuevo.otroComentario = null
        this.disOtro = true
      }

    },
    validaStatus(val){
      console.log(val);
      if(val == 1){
        this.siRules = false
        }else{
          this.siRules = true
        }
        console.log(this.siRules);
      if(val == 2){
        this.disRazon = false
        for(var a in this.aditionalComents){
          this.itemsComentarios.unshift(this.aditionalComents[a])
        }
      } else {
        this.disRazon = true
        this.clNuevo.razon = null
        for(var a in this.aditionalComents){
          var idx = this.itemsComentarios.findIndex(item => item.i == this.aditionalComents[a].i)
          if(idx !== -1){
            this.itemsComentarios.splice(idx, 1)
          }
        }
      }
    },
    validaStatusEdt(val){
      if(val == 1){
        this.siRules = false
        }else{
          this.siRules = true
        }
      if(val == 2){
        this.disRazonEdt = false
        for(var a in this.aditionalComentsEdt){
          this.itemsComentariosEdt.unshift(this.aditionalComentsEdt[a])
        }
      } else {
        this.disRazonEdt = true
        this.clEditar.razon = null
        for(var a in this.aditionalComentsEdt){
          var idx = this.itemsComentariosEdt.findIndex(item => item.i == this.aditionalComentsEdt[a].i)
          if(idx !== -1){
            this.itemsComentariosEdt.splice(idx, 1)
          }
        }
      }
    },
    getIncon(){
      let json ={
        c: "geppValida",
        cmd: "getTelIncJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };
     axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        this.inconsistencias = data.data
      })
    },
    newPagina(p){
      this.idsFiltrar = null
      this.pagination.page = p
      this.start = this.pagination.limit * (p - 1)
      this.pagination.currentPage = p

      this.getData()
    },
    newPaginaMatch(p) {
      //console.log(p);
      this.pagMatch.page = p
      this.pagMatch.start = this.pagMatch.limit * (p - 1)
      this.pagMatch.currentPage = p

      this.filterMatch()
    },
    cerrarNuevo(){
      //var elementos = document.getElementsByClassName('el-dialog__headerbtn'); // ó :
      //var elementos = elementoRaiz.getElementsByClassName(nombres);
      var evento = window.event;
      //console.log(evento.target.classList[0]);
      if(evento.target.classList[0] == 'el-dialog__close' && this.showAdd){
        this.showAdd = false
      }
      if(evento.target.classList[0] == 'el-dialog__close' && this.showEdit){
        this.showEdit = false
      }
    },
   reAsignarAc(){
      this.getUsuarios(1)
      this.idsAsignar = '',
      this.callCenter = null
      this.dgReAsignar = true
      this.idsAsignar = this.idsFiltrar
    },
    getPreventa(){
      if(this.cedi == -1){
        this.pre = null
        this.preventas = []

        return false
      }
      let json ={
        c: "geppValida",
        cmd: "getRtFinJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        cds: this.cedi
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data.data);
        this.preventas = data.data
        this.preventas.unshift({ i: -1, d: 'TODAS' })
      })
    },
    resetArchivo(file){
      this.resetFile = file.raw
      console.log(file.raw);
    },
    resetMasivos(){
      this.overlay = true

      const formData = new FormData();
      formData.append('wnd_upre_xls', this.resetFile);
      //formData.append('frm_ini_act', 0);

      axios.post(this.$api_url + "?c=CallCenterNewCapen&cmd=massiveReset&exec=self&hash="+this.hash+"&uid="+this.uid, formData)
      .then(data=>{
        //console.log(data.data);
        this.overlay = false
        this.dgReset = false
        if(data.data.success){
          this.$notify({
              title: '',
              message: 'Registros modificados con éxito.',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'success',
              timeout: 4000,
              icon: 'tim-icons icon-bell-55'
            });
        }else{
          this.$notify({
              title: '',
              message: 'Existe un error.',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'warning',
              timeout: 4000,
              icon: 'tim-icons icon-bell-55'
            });
        }
      })
    },
    valStatus(){
      if(this.clEditar.status !== 5){
        this.clEditar.razon = null
        this.clEditar.motivo = null
        this.motivos = []
      }
    },
    valCheck(){
      if(this.radioGroup == '0'){
        this.clEditar.razon = null
      }
    },
    sortTheHeadersAndUpdateTheKey(evt) {
      const headersTmp = this.headers;
      const oldIndex = evt.oldIndex;
      const newIndex = evt.newIndex;
      if (newIndex >= headersTmp.length) {
        let k = newIndex - headersTmp.length + 1;
        while (k--) {
          headersTmp.push(undefined);
        }
      }
      headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
      this.table = headersTmp;
      this.anIncreasingNumber += 1;
    },
  },
  mounted() {

    if(localStorage.getItem('key')) {
      this.hash = localStorage.getItem('key')
      this.uid = localStorage.getItem('uid')
      this.lvl = localStorage.getItem('level')
      // console.log( JSON.parse(localStorage.getItem('permission')));
      this.permisos = JSON.parse(localStorage.getItem('permission')).call_center_new_capen.actions

     ///console.log(this.permisos);

      var dat = new Date();
      var mo = (dat.getMonth() + 1) < 10 ? '0' + (dat.getMonth() + 1) : (dat.getMonth() + 1)
      var da = dat.getDate() < 10 ? '0' + dat.getDate() : dat.getDate()
      this.dateTimePicker[0] = dat.getFullYear() + '-' + mo + '-' + da
      this.dateTimePicker[1] = dat.getFullYear() + '-' + mo + '-' + da

        //console.log(this.permisos);
      this.getRegiones(0)
      this.getIncon()
      //this.getData()

    } else {
       localStorage.clear()
       this.$router.push('login');
    }

  },
  directives: {
    'sortable-table': {
      componentUpdated: (el) => {
        sortTableRows(el);
      },
      bind: (el) => {
        el.querySelectorAll('th').forEach((draggableEl) => {
          // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
          watchClass(draggableEl, 'sortHandle');
          draggableEl.classList.add('sortHandle');
        });
        sortTableRows(el);
      },
    },
  }
}
</script>
<style>
.v-btn {
  max-height: 20px;
  text-align: center;
}

.v-btn__content {
   font-size: 10px;
}


.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
   /*font-size: 11px !important;
   height: 20px;*/
   text-align: left;
   background-color: rgba(0, 0, 0, .05);
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 11px !important;
  /* height: 22px;*/
}

/*tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}*/

.theme--light.v-pagination .v-pagination__item--active {
  background-color: #124A98;
  color: #fff;
}

.stanby {
  background-color:#F2F5A9;
}

.new {
  background-color:#45B39D;
}

.danger {
  background-color:#F78181;
}

.pendint {
  background-color:#C8A2C8;
}
  .map {
    height: 500px;
    width: 100%;
  }

  .v-label {
    font-size: 12px;
  }

tr.v-data-table__selected {
    background: rgba(0, 0, 0, 0.24) !important; 
  }

  .vertical-text {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
  }

  .div-flex {
    display: flex;
  }

  .theme--light.v-messages {
    color: red;
  }

.truncate {
  max-width: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

html,body{
    overflow-y: hidden; 
}
</style>
